import { useState } from 'react';

function TooltipHover({ children, content, width }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <div className="tooltip-wrapper">
      <div className="tooltip-inner" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{children}</div>
      {showTooltip && (
        <div className="tooltip-item">
          <div className="tooltip-item__inner" style={{ width }}>
            <p>{content}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default TooltipHover