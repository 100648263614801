import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { concatAddress } from 'utils/concat';
import CopyItem from 'components/ui/CopyItem';
import Pagination from '@mui/material/Pagination';
import { Button } from '@mui/base/Button';
import { insertComma } from 'utils/insertComma';
import { getAssetsListAPI } from 'api/pages'
import { CircularProgress } from '@mui/material';
import useChainInfo from 'hooks/useChainInfo';

const tableHead = [
  "Token",
  "Contract"
]

const tableERC20Head = [
  "Transfers",
  "Holders"
]

const tableERC1155Head = [
  "Session",
  "TotalSupply",
  "Staked"
]

const chainList = [
  "Polygon",
  "Avalanche",
  "Base",
  "OpBNB",
  "Arbitrum",
  "XPLA"
]



const headersLookup = {
  "ERC20": tableERC20Head,
  "Erc1155": tableERC1155Head
};

function capitalizeFirstLetter(text) {
  if(!text) return "";
  return text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase();
}
function BodyItem({chainName, data, loading}) {
  const chainExplorerUrls = useSelector(state => { return state?.chain.chainExplorerUrls; });
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [tokenType, setTokenType] = useState("ERC20");
  const [formatData, setFormatData] = useState([]);
  const { setChainScanUrl } = useChainInfo();
  const changeTokenType = (value) => {
    setTokenType(value)
  }
  let tokenTypeList = {
    "TOKEN": "ERC20",
  }
  if (chainName != "XPLA") {
    tokenTypeList["NFT"] = "Erc1155"
  }

  console.log("BodyItem", chainName, data)

  useEffect(() => {
    const order = ["MPT", "BPT", "MPV", "MLGE"];
    const sortData = data?.sort((a, b) => {
        return order.indexOf(a.symbol) - order.indexOf(b.symbol);
    });
    setFormatData(sortData)
  }, [data])

  const dynamicHeaders = headersLookup[tokenType];

  return (
    <div className="body-box">
      <p className="body-box__title">{capitalizeFirstLetter(chainName)}</p>
      <div className="body-box__inner">
        <ul className="inner-select">
          {Object.entries(tokenTypeList).map(([key, value], index) => {
            return <li key={index}>
            <Button onClick={() => changeTokenType(value)}
              className={`small-btn ${tokenType === value ? 'isActive' : ''}`}>
              <p>{key}</p>
            </Button>
            </li>
          })}
        </ul>
        <div className={`inner-content ${loading ? 'loading-wrapper': ''}`}>
        {loading ?
          <CircularProgress color="inherit" size={70} />
          : <>
          {isMobile ? <div className="m-table-inner">
            <ul className="m-table-inner__header">
              <li><p>Token</p></li>
              <li><p>Contract</p></li>
            </ul>
            {formatData?.length ? formatData.map((body, index) => {
              if (body.contract_type_name !== tokenType || body.contract_name.includes('Locked')) return null;
              const symbolImg = `${body.symbol}.${body.contract_type_name === "ERC20" ? 'png' : body.contract_type_name === "Erc1155" ? 'gif' : ''}`
              return <div key={index} className="m-table-inner__item">
                <div className="item-head">
                  <div className="item-head__symbol">
                    <div className="item-head__symbol--img">
                      {body?.symbol ? <img className="token nft" src={require(`assets/Symbol/${symbolImg}`)} alt={`${symbolImg} symbol icon`} /> : null}
                      <img className="chain" src={require(`assets/Symbol/${body.chain_name}.svg`)} alt={`${body.chain_name} chain icon`} />
                    </div>
                    <p className="item-head__symbol--str">{body?.symbol ? body.symbol : ''}</p>
                  </div>
                  <div className="item-head__contract">{(body?.contract_address && Object.keys(chainExplorerUrls)?.length) ? <CopyItem open={setChainScanUrl(body.chain_name, body.contract_address, 'address')} label={concatAddress(body.contract_address)} value={body.contract_address} styled="start" /> : null}</div>
                </div>
                {tokenType === "ERC20" ?
                  <ul className="item-list">
                    <li>
                      <p>Transfers</p>
                      <span>{body?.tx_count ? insertComma(body.tx_count) : '-'}</span>
                    </li>
                    <li>
                      <p>Holders</p>
                      <span>{body?.holder ? insertComma(body.holder) : '-'}</span>
                    </li>
                  </ul> : (tokenType === "Erc1155" && Object.values(body?.staking_info)?.length) ?
                  <ul className="item-list column">
                    <li className="column-inner">
                      <p>Session</p>
                      <ul className="in-list">
                        {Object.keys(body.staking_info).map((itm, idx) => {
                        return <li key={idx}><span>{itm}</span></li>
                        })}
                      </ul>
                    </li>
                    <li className="column-inner">
                      <p>TotalSupply</p>
                      <ul className="in-list">
                        {Object.values(body.staking_info).map((value, idx) => {
                        return <li key={idx}><span>{value.totalSupply ? insertComma(value.totalSupply) : '-'}</span></li>
                        })}
                      </ul>
                    </li>
                    <li className="column-inner">
                      <p>Staked</p>
                      <ul className="in-list">
                        {Object.values(body.staking_info).map((value, idx) => {
                        return <li key={idx}><span>{value.staked ? insertComma(value.staked) : '-'}</span></li>
                        })}
                      </ul>
                    </li>
                  </ul>
                : null}
              </div>
            }) : null}
          </div> :
            <table className="table-inner">
              <colgroup>
                {tableHead.map((head, index) => {
                  return <col key={index} className={head} />;
                })}
                {(tokenType && dynamicHeaders) ? dynamicHeaders.map((head, index) => {
                  return <col key={index} className={head} />;
                }) : null}
              </colgroup>
              <thead className="table-inner__head">
                <tr>
                  {tableHead.map((head, index) => {
                    return <th key={index} className={head}><p>{head}</p></th>
                  })}
                  {(tokenType && dynamicHeaders) ? dynamicHeaders.map((head, index) => {
                  return <th key={index} className={head}><p>{head}</p></th>
                }) : null}
                </tr>
              </thead>
              <tbody className="table-inner__body">
                {formatData?.length ? formatData.map((body, index) => {
                  if (body.contract_type_name !== tokenType || body.contract_name.includes('Locked')) return null;
                  const symbolImg = `${body.symbol}.${body.contract_type_name === "ERC20" ? 'png' : body.contract_type_name === "Erc1155" ? 'gif' : ''}`
                  return <tr key={index}>
                    <td className="symbol">
                      <div className="symbol__inner">
                        <div className="symbol__inner--img">
                          {body?.symbol ? <img className="token" src={require(`assets/Symbol/${symbolImg}`)} alt={`${symbolImg} symbol icon`} /> : null}
                          <img className="chain" src={require(`assets/Symbol/${body?.chain_name}.svg`)} alt={`${body?.chain_name} chain icon`} />
                        </div>
                        <p className="symbol__inner--str">{body?.symbol ? body.symbol : "-"}</p>
                      </div>
                    </td>
                    <td className="concat">{(body?.contract_address && Object.keys(chainExplorerUrls)?.length) ? <CopyItem open={setChainScanUrl(body.chain_name, body.contract_address, 'address')} label={concatAddress(body.contract_address)} value={body.contract_address} styled="start" /> : null}</td>
                    {tokenType === "ERC20" ? <>
                      <td className="str"><p>{body?.tx_count ? insertComma(body.tx_count) : '-'}</p></td>
                      <td className="str"><p>{body?.tx_count ? insertComma(body.holder) : '-'}</p></td>
                    </> :
                    (tokenType === "Erc1155" && Object.values(body?.staking_info)?.length) ? <>
                      <td className="list">
                        <ul>
                          {Object.keys(body.staking_info).map((itm, idx) => {
                          return <li key={idx}><p>{itm}</p></li>
                          })}
                        </ul>
                      </td>
                      <td className="list">
                        <ul>
                          {Object.values(body.staking_info).map((value, idx) => {
                          return <li key={idx}><p>{value.totalSupply ? insertComma(value.totalSupply) : '-'}</p></li>
                          })}
                        </ul>
                      </td>
                      <td className="list">
                        <ul>
                          {Object.values(body.staking_info).map((value, idx) => {
                          return <li key={idx}><p>{value.staked ? insertComma(value.staked) : '-'}</p></li>
                          })}
                        </ul>
                      </td>
                    </> : null}
                  </tr>
                }) : null}
              </tbody>
            </table>}
          </>}
        </div>
      </div>
    </div>
  )
}

export default function TokensNfts() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getList = async () => {
    setLoading(true)
    try {
      const res = await getAssetsListAPI();
      const order = ['POLYGON', 'AVALANCHE', 'BASE', 'OPBNB', 'ARBITRUM', 'XPLA'];
      const sortedData = Object.fromEntries(order.map(key => [key, res[key]]));
      setData(sortedData)
    }
    catch(e) {
      console.log("e: ", e)
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <div className="g-max-width section-layout tokens-nfts grid-row-layout">
      <div className="section-layout__header">
        <p className="section-title tokens-nfts">TOKENS & NFTS</p>
      </div>
      <div className="grid-row-layout__body">
        {loading ? chainList.map(item => {
          return<BodyItem loading={loading} chainName={item} key={item} />
        }) : Object.keys(data)?.length ? Object.entries(data).map(([key, value]) => {
          return <BodyItem chainName={key} data={value} key={key} />
        }) : null}
      </div>
    </div>
  )
}
