import axios from 'axios';
// import store from 'store/configureStore';

// let BASE_URL = 'https://aeb30aa9-e921-4003-952a-80475ca600f2.mock.pstmn.io';
let BASE_URL = 'https://miraclescanapi.meverselabs.io';

export const apiClient = axios.create({
  baseURL: BASE_URL,
});

// export const apiRequest = async (config) => {
//   const state = store.getState();
//   const jwtToken = state?.user?.jwtToken;
//   const idToken = state?.user?.idToken;

//   config.headers = {
//     Authorization: jwtToken,
//     Email: idToken?.email,
//     ...(config.headers || {}),
//   };

//   try {
//     const response = await apiClient(config);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };