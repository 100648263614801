import { apiClient } from 'utils/axios';

export const getPlayListAPI = async (page, limit) => {
  return new Promise((resolve, reject) => {
    const url = `/play/list?page=${page}&limit=${limit}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getTxListAPI = async (page, limit) => {
  return new Promise((resolve, reject) => {
    const url = `/txlist?page=${page}&limit=${limit}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getAssetsListAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/assets`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getResourcesInfoAPI = async (chain) => {
  return new Promise((resolve, reject) => {
    const url = `/resources/information?chain=${chain}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getDailyTransactionChartAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/resources/dailyTransaction`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getVolumeOfMiracleChartAPI = async () => {
  return new Promise((resolve, reject) => {
    // const url = `/resources/volumeOfMiracle`
    const url = `/resources/futureDesignSupply`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getHistoricalActivityChartAPI = async (chain, game) => {
  return new Promise((resolve, reject) => {
    const url = `/resources/historicalActivity?chain=${chain}&game=${game}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getManagedWalletBalanceAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/resources/managedWalletBalance`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getEcosystemTokenUsageAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/resources/ecosystemTokenUsage`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getWeeklyStateListAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/resources/weeklyStateList`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getweeklyStateAPI = async (date) => {
  return new Promise((resolve, reject) => {
    const url = `/resources/weeklyState?date=${date}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getMintedCirculatingAPI = async (date) => {
  return new Promise((resolve, reject) => {
    const url = `/resources/mintedCirculating?date=${date}`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getGameListAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/gameList`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}
