import { apiClient } from 'utils/axios';

export const getMainSummaryAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/home/summary`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getLastEventListAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/lastEventList`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}
