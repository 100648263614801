import { useState, useEffect } from "react";
import TooltipHover from 'components/ui/TooltipHover'
import { getDateTimezoneEn, timeBefore } from 'utils/timeFormat';
import CopyItem from 'components/ui/CopyItem';
import { useLocation } from 'react-router-dom';
import constants from 'constants';
import { useSelector } from 'react-redux';
import { concatAddress } from 'utils/concat';
import useChainInfo from 'hooks/useChainInfo';
import { CircularProgress } from '@mui/material';

const chain = constants.chain
const token = constants.token


function InfoTooltip({content}) {
  return (
    <div></div>
    // <TooltipHover width="auto" content={content}>
    //   <div className="info-icon"></div>
    // </TooltipHover>
  )
}
export default function PlayDetails() {
  // const { id } = useParams();
  const chainExplorerUrls = useSelector(state => { return state?.chain.chainExplorerUrls; });
  const { setChainScanUrl } = useChainInfo();
  const location = useLocation();
  const data = location.state;
  // const innerDtata = data?.JsonData ? JSON.parse(data.JsonData) : null;
  // console.log("innerDtata", innerDtata)

  // if (!Object.keys(chainExplorerUrls)?.length) return null;
  return (
    <div className="g-max-width section-layout detail-layout">
      <div className="section-layout__header">
        <p className="section-title no-icon">PLAY DETAIL</p>
        {/* <p className="section-length">Showing <span>15</span> from approximately <span>1,294,175,297</span> transactions</p> */}
      </div>
      <div className="detail-layout__body">
        <div className="body-box">
        {Object.keys(chainExplorerUrls)?.length ? <div>
          <ul className="body-box__list">
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Transaction Hash" />
                <p className="title__text">Transaction Hash:</p>
              </div>
              <div className="inner">
                {data?.TxHash && data?.ChainID ? <CopyItem open={setChainScanUrl(data.ChainID, data.TxHash, 'tx')} value={data.TxHash} styled="start" /> : <p>-</p>}
              </div>
            </li>
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Chain" />
                <p className="title__text">Chain:</p>
              </div>
              <div className="inner">
                <div className="symbol">
                  {data.ChainID ? <img src={require(`assets/Symbol/${chain[data.ChainID]}.svg`)} alt={`${chain[data.ChainID]}} symbol icon`} /> : null}
                  {/* <img src={require(`assets/Symbol/${data.chain.symbol}.svg`)} alt={`${data.chain.symbol} symbol icon`} /> */}
                  <p>{chain[data.ChainID].charAt(0).toUpperCase() + chain[data.ChainID].slice(1).toLowerCase()}</p>
                </div>
              </div>
            </li>
            {/* <li>
              <div className="title no-icon">
                <InfoTooltip content="Status" />
                <p className="title__text">Status:</p>
              </div>
              <div className="inner">
                {data?.status ? <div className={`status ${data.status}`}><span>{data.status}</span></div> : null}
              </div>
            </li> */}
            <li>
              <div className="title no-icon">
                <InfoTooltip content="TimeStamp" />
                <p className="title__text">TimeStamp:</p>
              </div>
              <div className="inner">
                <div className="time">
                  <p>{`${data?.CreatedAt ? timeBefore(new Date(data.CreatedAt).getTime()) : ''} ${data?.CreatedAt ? `(${getDateTimezoneEn(new Date(data.CreatedAt).getTime())})` : ''}`}</p>
                </div>
              </div>
            </li>
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Game" />
                <p className="title__text">Game:</p>
              </div>
              <div className="inner"><p>{(data.ContractName && data.ContractType) ? data.ContractName.replace(`_${data.ContractType}`, '') : '-'}</p></div>
            </li>
            <li>
              <div className="title rows">
                <div className="method-icon"></div>
                <p className="title__text">Method:</p>
              </div>
              <div className="inner">
                <p>{`${data.AbiName ? data.AbiName : '-'}`}</p>
                {/* <p>{`${data.AbiName ? data.AbiName : '-'} ${data.amount} ${data.chain.token}`}</p> */}
              </div>
            </li>
          </ul>
          <ul className="body-box__list">
            <li>
              <div className="title no-icon">
                <InfoTooltip content="From" />
                <p className="title__text">From:</p>
              </div>
              <div className="inner">
                {data?.From ? <CopyItem open={setChainScanUrl(data.ChainID, data.From, 'address')} value={data.From} styled="start" /> : <p>-</p>}
              </div>
            </li>
            {/* <li>
              <div className="title no-icon">
                <InfoTooltip content="To" />
                <p className="title__text">To:</p>
              </div>
              <div className="inner">
                {data?.To ? <CopyItem open={setChainScanUrl(data.ChainID, data.From, 'address')} value={data.To} styled="start" /> : '-'}
              </div>
            </li> */}
          </ul>
          {/* <ul className="body-box__list">
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Value" />
                <p className="title__text">Value:</p>
              </div>
              <div className="inner rows">
                <p>{`${data.value} ${data.chain.token}`}</p><span>($0,000.00)</span>
              </div>
            </li>
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Transaction Fee" />
                <p className="title__text">Transaction Fee:</p>
              </div>
              <div className="inner rows">
                <p>{`${data.fee} ${data.chain.token}`}</p><span>($0,000.00)</span>
              </div>
            </li>
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Gas Price" />
                <p className="title__text">Gas Price:</p>
              </div>
              <div className="inner rows">
                <p>{`${data.gasPrice} ${data.chain.token}`}</p><span>($0,000.00)</span>
              </div>
            </li>
          </ul> */}
        </div> : <div className="body-box__loading"><CircularProgress color="inherit" size={70} /></div>}</div>
        {/* <div className="body-box">
          <ul className="body-box__list">
            <li>
              <div className="title no-icon">
                <p className="title__text">More Details:</p>
              </div>
              <div className="inner">
                <p className="btn">+ Click to show more</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="body-box">
          <ul className="body-box__list">
            <li>
              <div className="title no-icon">
                <InfoTooltip content="Private Note" />
                <p className="title__text">Private Note:</p>
              </div>
              <div className="inner rows">
                <p>To access the Private Note feature, you must be <span className="btn">Logged in</span></p>
              </div>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
