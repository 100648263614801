import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Button } from '@mui/base/Button';
import CustomTooltip from './CustomTooltip';
import { formatNumber, formatDate } from 'utils/chartDataFormat';
import { insertComma } from 'utils/insertComma';
import { getVolumeOfMiracleChartAPI } from 'api/pages';
import { CircularProgress } from '@mui/material';

export default function DistributionVolumeChart() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [period, setPeriod] = useState(7);
  const [dataMaxValue, setDataMaxValue] = useState(0);
  const periodList = {
    "7D": 7,
    "30D": 30,
    "90D": 90,
    "1Y": 365,
    "All": 0
  }

  const setPeriodFormattedData = (date) => {
    let result = [];
    if (date === 0) {
      result = data.filter(el => {
        return Object.values(el).every(value => value !== null);
      })
    } else {
      const formatList = data.filter(el => {
        const hasFutureKey = Object.keys(el).some(key => key.includes("future"));
        return !hasFutureKey && Object.values(el).every(value => value !== null);
      })
      result = formatList.slice(-date)
    }
    getMaxValue(result);
    setFormattedData(result)
  }

  const getMaxValue = (formatData) => {
    if (!formatData || !formatData?.length) return;
    const maxValue = formatData.reduce((max, obj) => {
        const values = Object.values(obj).map(value => Number(value)).filter(value => !isNaN(value));
        return Math.max(max, ...values);
    }, -Infinity);
    setDataMaxValue(maxValue)
  };

  const getVolumeOfMiracleChart = async () => {
    setLoading(true)
    try {
      const res = await getVolumeOfMiracleChartAPI();
      const result = res === null ? [] : res;
      setData(result);
    }
    catch(e) {
      console.log("e: ", e)
    }
    finally {
      setLoading(false)
    }
  }

  const changeChartPeriod = async (value) => {
    if (loading || period === value) return;
    setPeriod(value)
    setPeriodFormattedData(value)
  }

  useEffect(() => {
    setPeriodFormattedData(period);
  }, [data])

  useLayoutEffect(() => {
    getVolumeOfMiracleChart();
  }, [])

  const getQuarterTicks = (data) => {
    const quarterEndDates = [];
    const quarters = ['03-31', '06-30', '09-30', '12-31'];

    data?.forEach(d => {
      const date = d?.date.split('-').slice(1).join('-');
      if (quarters.includes(date)) {
        quarterEndDates.push(d?.date);
      }
    });
    return quarterEndDates;
  };

  const CustomTickFormatter = (tick) => {
    const date = new Date(tick);
    const year = date.getFullYear().toString().slice(-2);
    const month = date.getMonth() + 1;
    if (month <= 3) return `${year}Q1`;
    if (month <= 6) return `${year}Q2`;
    if (month <= 9) return `${year}Q3`;
    return `${year}Q4`;
  };

  const quarterTicks = getQuarterTicks(data);
  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="chart-header__default">
          <div className="title">
            <p>DISTRIBUTION VOLUME OF MIRACLE PLAY TOKEN</p>
          </div>
          <ul className="chart-select">
            {Object.entries(periodList).map(([key, value], index) => {
              return <li key={index}>
              <Button onClick={() => changeChartPeriod(value)} disabled={loading}
                className={`small-btn ${period === value ? 'isActive' : ''}`}>
                <p>{key}</p>
              </Button>
              </li>
            })}
          </ul>
        </div>
      </div>
      {!loading && !formattedData.length ?
        <div className="chart-nodata">
          <div className="chart-nodata__inner">
            <p>NO DATA</p>
            <span>No data, please try again later</span>
          </div>
        </div> :
        <>
        <div className="chart-box large-chart-box">
          {loading ? <div className="progress-wrapper"><CircularProgress size={55} color="inherit" /></div> :formattedData.length ?
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={formattedData}
              className="main-value-chart"
              margin={{
                top: 0,
                right: 20,
                bottom: 10,
                left: isMobile ? -15 : 40,
              }}
              >
              <CartesianGrid
                horizontal={true} // 수평선
                vertical={true} // 수직선
                stroke="#51515E"
                strokeDasharray="2 2"
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="bottom"
                iconSize={30}
                wrapperStyle={{ fontSize: 11, bottom: 0, paddingTop: 10, left: '50%', transform: 'translateX(-50%)' }} />

              <Line type="linear" dataKey="designed distribution volume" strokeWidth={2} dot={false} legendType="plainline"
                  stroke="#fff" activeDot={{ r: 2 }} strokeDasharray="5 5" />
              <Line type="linear" dataKey="future designed distribution volume" strokeWidth={2} dot={false} legendType="none" strokeOpacity={0.6}
                  stroke="#a1a1a1" activeDot={{ r: 2 }} strokeDasharray="5 5" />

              <Line type="linear" dataKey="Total on-chain distribution" strokeWidth={2} stroke="#fff" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Total on-chain distribution" strokeWidth={2} stroke="#fff" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              <Line type="linear" dataKey="Polygon Chain" strokeWidth={2} stroke="#823BDE" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Polygon Chain" strokeWidth={2} stroke="#823BDE" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              <Line type="linear" dataKey="Avalanche Chain" strokeWidth={2} stroke="#E84142" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Avalanche Chain" strokeWidth={2} stroke="#E84142" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              <Line type="linear" dataKey="Base Chain" strokeWidth={2} stroke="#0057FF" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Base Chain" strokeWidth={2} stroke="#0057FF" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              <Line type="linear" dataKey="opBNB Chain" strokeWidth={2} stroke="rgb(240, 185, 11)" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future opBNB Chain" strokeWidth={2} stroke="rgb(240, 185, 11)" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              <Line type="linear" dataKey="Arbitrum Chain" strokeWidth={2} stroke="rgb(18 170 255)" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Arbitrum Chain" strokeWidth={2} stroke="rgb(18 170 255)" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}
              <Line type="linear" dataKey="Xpla Chain" strokeWidth={2} stroke="#01b2ff" dot={false} legendType="plainline"
                activeDot={{ r: 2 }} />
              {/* <Line type="linear" dataKey="future Xpla Chain" strokeWidth={2} stroke="#01b2ff" dot={false} legendType="none" strokeOpacity={0.6}
                activeDot={{ r: 2 }} /> */}

              {/* x축 중심선 */}
              <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} ticks={(period === 0 || period === 365) ? quarterTicks : null} tickFormatter={(period === 0 || period === 365) ? CustomTickFormatter : formatDate} />
              {/* y축 중심선 */}
              <YAxis type="number"
               domain={[0, period === 0 ? dataMaxValue : "auto"]}
              tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile? formatNumber : insertComma} />
            </LineChart>
          </ResponsiveContainer> : null}
        </div>
      </>}
    </div>
  )
}