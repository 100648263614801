import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuOpenImg from 'assets/Icon/menu.svg'
import MenuCloseImg from 'assets/Icon/close.svg'
import { GNB_ITEMS as GnbList } from 'constants/gnbList'
import SearchInput from 'components/ui/input/SearchInput'
import DefaultLogo from 'assets/Logo/header-logo.png'
import MobileLogo from 'assets/Logo/m-header-logo.svg'

function Header() {
  const nav = useNavigate();
  const location = useLocation();
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const screenWidth = useSelector(state => { return state?.ui?.screen?.size.width; });
  const [menu, setMenu] = useState(false);

  const searchInputStyle = {
    id: "total-search",
    placeholder: 'Search anything on MiracleScan',
    size: "Small"
  }

  const movePath = (pathName) => {
    nav(pathName)
    if (isMobile) setMenu(false);
  }

  return (
    <>
      <header>
        <div className="navigation">
          <div className="navigation__inner">
            <div className={`actions ${location.pathname !== '/' ? 'double' : ''}`}>
              <div onClick={() => movePath('/')} className="logo mobileLogo"><img src={MobileLogo} alt="Mobile Miracle Play Logo" /></div>
              <div onClick={() => movePath('/')} className="logo defaultLogo"><img src={DefaultLogo} alt="Default Miracle Play Logo" /></div>
              {location.pathname !== '/' && screenWidth >= 1400 ? <SearchInput {...searchInputStyle} /> : null}
            </div>
            {isMobile ? <img onClick={() => setMenu(!menu)} src={menu ? MenuCloseImg : MenuOpenImg} className="menu-btn" alt="mobile menu icon" /> :
            <ul className="gnb-list">
              {GnbList.map((item, index) => {
                return <li onClick={() => movePath(item.pathName)} key={index}><p>{item.name}</p></li>
              })}
            </ul>}
          </div>
        </div>
        {location.pathname !== '/' ?
        <div className="m-search-bar">
          <SearchInput {...searchInputStyle} />
        </div> : null}
      </header>
      {isMobile && <div className={`m-gnb-list ${menu ? 'isActive': ''}`}>
        <ul className="m-gnb-list__inner">
          {GnbList.map((item, index) => {
            return <li onClick={() => movePath(item.pathName)} key={index}><p>{item.name}</p></li>
          })}
        </ul>
      </div>}
    </>
  );
}

export default Header;
