import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLogoImg from 'assets/Logo/main-logo.svg';
import SearchInput from 'components/ui/input/SearchInput';
import MPTsymbol from 'assets/Symbol/MPT.png'
import { concatAddress } from 'utils/concat';
import BaseButton from 'components/ui/button/BaseButton';
// import TooltipHover from 'components/ui/TooltipHover'
import DistributionVolumeChart from 'components/charts/DistributionVolumeChart';
import DaliyTransactionsChart from 'components/charts/SmallDaliyTransactionsChart';
import EcosystemTokenChart from 'components/charts/EcosystemTokenChart';
import CopyItem from 'components/ui/CopyItem';
import { CircularProgress } from '@mui/material';
import { getMainSummaryAPI, getLastEventListAPI } from 'api/main'
import { getManagedWalletBalanceAPI } from 'api/pages'
import { LayoutGroup, AnimatePresence, motion } from "framer-motion";
import { insertComma } from 'utils/insertComma';
import { toNumberFormat } from 'utils/toNumberFormat';
import { getDateTimezoneEn, timeBefore } from 'utils/timeFormat';
import useChainInfo from 'hooks/useChainInfo';
import constants from 'constants';
import SupportLogo1 from 'assets/Logo/Support/1.svg';
import SupportLogo2 from 'assets/Logo/Support/2.svg';
import SupportLogo4 from 'assets/Logo/Support/4.svg';
import SupportLogo5 from 'assets/Logo/Support/5.svg';
import SupportLogo6 from 'assets/Symbol/AVALANCHE.svg';
import SupportLogo7 from 'assets/Symbol/POLYGON.svg';
import SupportLogo8 from 'assets/Symbol/ARBITRUM.svg';
import SupportLogo9 from 'assets/Symbol/XPLA.svg';
import LinkIcon from 'assets/Icon/link.svg'

const chain = constants.chain
const token = constants.token


function LatestPlay({data, isMobile}) {
  const nav = useNavigate();
  const tableHead = [
    "chain",
    "game",
    "hash",
    "tx type",
    // "fees",
  ]
  const pathMove = (body) => {
    nav('/play/detail', { state: body });
  }
  return (
    <div className="table-container latest-play-list main">
      {isMobile ? <div className="m-table-inner main">
        {data?.length ? data.slice(0, 5).map((body, index) => {
          return <div key={index} className="m-table-inner__item">
            <div className="item-head">
              <div className="item-head__symbol">
                {body.ChainID ? <img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]}} symbol icon`} /> : null}
                <p>{(body.ContractName && body.ContractType) ? body.ContractName.replace(`_${body.ContractType}`, '') : '-'}</p>
              </div>
              <div className="item-head__fee"><p>{body.fee ? body.fee : '-'}</p><span>{token[body.ChainID] ? token[body.ChainID] : '-'}</span></div>
            </div>
            <ul className="item-list">
              <li>
                <p>HASH</p>
                <span onClick={() => pathMove(body)} style={{cursor: 'pointer'}}>{body.TxHash ? concatAddress(body.TxHash) : '-'}</span>
              </li>
              <li>
                <p>TX Type</p>
                <span>{body.AbiName ? body.AbiName : '-'}</span>
              </li>
            </ul>
          </div>
        }) : null}
      </div>
      : <table className="small-table-inner">
        <colgroup>
          {tableHead.map((head, index) => {
            return <col key={index} className={head} />;
          })}
        </colgroup>
        <thead className="small-table-inner__head">
          <tr>{tableHead.map((head, index) => {
            return <th key={index} className={head}><p>{head}</p></th>
          })}</tr>
        </thead>
        <LayoutGroup>
          <motion.tbody className="small-table-inner__body">
            <AnimatePresence>
              {data?.length ? data.map(body => {
                return <motion.tr
                  layout
                  variants={{
                    pre: { opacity: 0 },
                    visible: { opacity: 1 }
                  }}
                  initial="pre"
                  animate="visible"
                  key={body.ID}
                >
                  <td className="symbol">{body.ChainID ?<img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]} symbol icon`} /> : null}</td>
                  <td className="str"><p>{(body.ContractName && body.ContractType) ? body.ContractName.replace(`_${body.ContractType}`, '') : '-'}</p></td>
                  {/* <td className="str"><p>{body.ID}</p></td> */}
                  <td className="concat link"><p onClick={() => pathMove(body)}>{body.TxHash ? concatAddress(body.TxHash, 8) : '-'}</p></td>
                  <td className="concat"><p>{body.AbiName ? body.AbiName : null}</p></td>
                  {/* <td className="two"><div className="two__inner"><p>{body.fee ? body.fee : '-'}</p><span>{token[body.ChainID] ? token[body.ChainID] : '-'}</span></div></td> */}
                </motion.tr>
              }) : null}
            </AnimatePresence>
          </motion.tbody>
        </LayoutGroup>
      </table>}
    </div>
  )
}

function LatestTransactions({data, isMobile}) {
  const { setChainScanUrl } = useChainInfo();
  const tableHead = [
    "chain",
    "game",
    "hash",
    "method",
    "from-to",
  ]
  return (
    <div className="table-container latest-transaction-list">
      {isMobile ? <div className="m-table-inner main">
        {data?.length ? data.slice(0, 5).map((body, index) => {
          return <div key={index} className="m-table-inner__item">
            <div className="item-head">
              <div className="item-head__symbol">
                {body.ChainID ? <img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]} symbol icon`} /> : null}
                <p>{body.ContractAddress ? concatAddress(body.ContractAddress) : '-'}</p>
              </div>
              <div className="item-head__method"><p>{body.AbiName ? body.AbiName : '-'}</p></div>
            </div>
            <ul className="item-list">
              <li>
                <p>HASH</p>
                <span onClick={() => window.open(setChainScanUrl(body.ChainID, body.TxHash, 'tx'))} style={{cursor: 'pointer'}}>{body.TxHash ? concatAddress(body.TxHash) : '-'}</span>
              </li>
              <li>
                <p>FROM-TO</p>
                <div className="fromTo">
                  <span onClick={() => window.open(setChainScanUrl(body.ChainID, body.From, 'address'))} style={{cursor: 'pointer'}}>{body.From ? body.From : "-"}</span>
                  <div className="next-icon"></div>
                  <span onClick={() => window.open(setChainScanUrl(body.ChainID, body.To, 'address'))} style={{cursor: 'pointer'}}>{body.To ? body.To : "-"}</span>
                </div>
              </li>
            </ul>
          </div>
        }) : null}
      </div>
      : <table className="small-table-inner">
          <colgroup>
            {tableHead.map((head, index) => {
              return <col key={index} className={head} />;
            })}
          </colgroup>
          <thead className="small-table-inner__head">
            <tr>{tableHead.map((head, index) => {
              return <th key={index} className={head}><p>{head}</p></th>
            })}</tr>
          </thead>
          <LayoutGroup>
            <motion.tbody layout className="small-table-inner__body">
              <AnimatePresence>
                {data?.length ? data.map(body => {
                  return <motion.tr
                    layout
                    variants={{
                      pre: { opacity: 0 },
                      visible: { opacity: 1 }
                    }}
                    initial="pre"
                    animate="visible"
                    key={body.ID}
                  >
                    <td className="symbol">{body.ChainID ? <img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]} symbol icon`} /> : null}</td>
                    <td className="str"><p>{body.ContractAddress ? concatAddress(body.ContractAddress) : '-'}</p></td>
                    {/* <td className="str"><p>{body.ID}</p></td> */}
                    <td className="concat"><p onClick={() => window.open(setChainScanUrl(body.ChainID, body.TxHash, 'tx'))} style={{cursor: 'pointer'}}>{body.TxHash ? concatAddress(body.TxHash) : '-'}</p></td>
                    <td className="method"><div className="method__inner"><p>{body.AbiName ? body.AbiName : '-'}</p></div></td>
                    <td className="fromTo"><div className="fromTo__inner">
                      <p onClick={() => window.open(setChainScanUrl(body.ChainID, body.From, 'address'))} style={{cursor: 'pointer'}}>{body.From ? body.From : "-"}</p>
                      <div className="next-icon"></div>
                      <p onClick={() => window.open(setChainScanUrl(body.ChainID, body.To, 'address'))} style={{cursor: 'pointer'}}>{body.To ? body.To: "-"}</p></div></td>
                  </motion.tr>
                }) : null}
              </AnimatePresence>
            </motion.tbody>
          </LayoutGroup>
      </table>}
    </div>
  )
}

function TokenInfoLayoutItem({title, data}) {
  const mobeScan = (data) => {
    if (!data || !data?.Scan) return;
    window.open(data?.Scan);
  }
  return (
    <li className={!data ? "hide" : ""}>
      <>
        <div className="title">
          <p>{title ? title : "-"}</p>
          {data?.Scan ? <img onClick={() => mobeScan(data)} className="link-icon" src={LinkIcon} alt="link icon" /> : null}
        </div>
        <div className="inner">
          <p>{data?.Balance ? toNumberFormat(data.Balance, 2) : typeof data === 'number' ? toNumberFormat(data, 2) : "0"} MPT</p>
        </div>
      </>
    </li>
  )
}

function FoundationLayoutTable({list, loading, scanLink}) {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const tableHead = [
    "foundation",
    "age",
    "hash",
    "from-to",
    "amount"
  ]

  return (
    <div className="table-container managed-tx-list">
      {isMobile ? <div className="m-table-inner">
      {loading ? <div className="m-table-inner__loading"><CircularProgress size={50} color="inherit" /></div> : list?.length ? list.slice(0, 5).map((body, index) => {
          return <div key={index} className="m-table-inner__item">
            <div className="item-head">
              <div className="item-head__symbol">
                <img src={require(`assets/Symbol/${chain[137]}.svg`)} alt={`${chain[137]}} symbol icon`} />
              </div>
            </div>
            <ul className="item-list">
              <li>
                <p>HASH</p>
                <span onClick={() => window.open((`${scanLink}/tx/${body.tx_hash}`))} style={{cursor: 'pointer'}}>{body?.tx_hash ? concatAddress(body.tx_hash) : '-'}</span>
              </li>
              <li>
                <p>FROM-TO</p>
                <div className="fromTo">
                  <span onClick={() => window.open(`${scanLink}/address/${body.from}`)} style={{cursor: 'pointer'}}>{body?.from ? body.from : '-'}</span>
                  <div className="next-icon"></div>
                  <span onClick={() => window.open(`${scanLink}/address/${body.to}`)} style={{cursor: 'pointer'}}>{body?.to ? body.to : '-'}</span>
                </div>
              </li>
            </ul>
            <ul className="item-foot">
              <li>
                <p className="first">{body?.created_at ? timeBefore(new Date(body.created_at).getTime()) : '-'}</p>
                <span>{body?.created_at ? getDateTimezoneEn(new Date(body.created_at).getTime()) : '-'}</span>
              </li>
              <li>
                <span className="first">Amount</span>
                <div className="fee"><p>{body?.amount ? toNumberFormat(body.amount, 2) : "-"}</p><span>MPT</span></div>
              </li>
            </ul>
          </div>
        }) : null}
      </div>
      : <table className="table-inner">
        <colgroup>
          {tableHead.map((head, index) => {
            return <col key={index} className={head} />;
          })}
        </colgroup>
        <thead className="table-inner__head">
          <tr>{tableHead.map((head, index) => {
            return <th key={index} className={head}><p>{head}</p></th>
          })}</tr>
        </thead>
        {loading ?
        <tbody className="table-inner__loading">
          <tr className="inner"><td className="inner__progress"><CircularProgress size={60} color="inherit" /></td><td></td></tr>
          {[...Array(5)].map((_, index) => (<tr key={index}><td></td><td></td><td></td><td></td><td></td><td></td></tr>))}
        </tbody> :
        <tbody className="table-inner__body">
          {list?.length ? list.slice(0, 5).map((body, index) => {
            return <tr key={index}>
              <td className="str"><p>{body.wallet_tag ? body.wallet_tag : '-'}</p></td>
              <td className="column">
                <div className="column__inner">
                  <p>{body?.created_at ? timeBefore(new Date(body.created_at).getTime()) : '-'}</p>
                  <span>{body?.created_at ? getDateTimezoneEn(new Date(body.created_at).getTime()) : '-'}</span>
                </div>
              </td>
              <td className="concat">{body?.tx_hash ? <CopyItem data={body} open={`${scanLink}/tx/${body.tx_hash}`} value={body.tx_hash} styled="start" /> : '-'}</td>
              {/* <td className="concat"><p style={{cursor: 'pointer'}} onClick={() => window.open((`${scanLink}/tx/${body.tx_hash}`))}>{body?.tx_hash ? body.tx_hash : '-'}</p></td> */}
              <td className="fromTo">
                <div className="fromTo__inner">
                  {body?.from ? <CopyItem open={(`${scanLink}/address/${body.from}`)} label={concatAddress(body.from)} value={body.from} styled="start" /> : '-'}
                  <div className="next-icon"></div>
                  {body?.to ? <CopyItem open={(`${scanLink}/address/${body.to}`)} label={concatAddress(body.to)} value={body.to} styled="start" /> : '-'}
                </div>
              </td>
              <td className="two"><div className="two__inner"><p>{body?.amount ? toNumberFormat(body.amount, 2) : "-"}</p><span>MPT</span></div></td>
            </tr>
          }) : null}
        </tbody>}
      </table>}
    </div>
  )
}

function FoundationLayout() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [fund, setFund] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [scanLink, setScanLink] = useState([]);
  const getManagedWalletBalance = async () => {
    setLoading(true)
    try {
      const res = await getManagedWalletBalanceAPI()
      setFund(res?.fund)
      setList(res?.latest_Tx)
      setScanLink(res?.scan)
    }
    catch(e) {
      console.error("e: ", e)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getManagedWalletBalance()
  }, [])

  return (
    <div className="container-box foundation">
      <p className="container-box__title">FOUNDATION ADDRESSES</p>
      <div className="foundation__inner address">
        <ul className="row-item">
          <TokenInfoLayoutItem title="Project R&D" data={fund?.["R&D"]} />
          <TokenInfoLayoutItem title="Project Operation" data={fund?.["Project Operation"]} />
          <TokenInfoLayoutItem title="Marketing & Listing" data={fund?.["Marketing"]} />
        </ul>
        <ul className="row-item">
          <TokenInfoLayoutItem title="Miracle Prize Pool" data={fund?.["Tournament Prize"]} />
          <TokenInfoLayoutItem title="Miracle Club Pool" data={fund?.["Tournament Club reward"]} />
          <TokenInfoLayoutItem title="Reserve" data={fund?.["Reserve"]} />
        </ul>
        <ul className="row-item">
          <TokenInfoLayoutItem title="DAO" data={fund?.["DAO"]} />
          {!isMobile ?
          <>
            <li></li>
            <li></li>
          </> : null}
        </ul>
      </div>
      <p className="container-box__title">DIGITAL ASSET CUSTODY ADDRESS (KODA COLD WALLET)</p>
      <div className="foundation__inner address">
        <ul className="row-item">
          <TokenInfoLayoutItem title="Project R&D" data={fund?.["KODA R&D"]} />
          <TokenInfoLayoutItem title="Project Operation" data={fund?.["KODA Project Operation"]} />
          <TokenInfoLayoutItem title="Marketing & Listing" data={fund?.["KODA Marketing"]} />
        </ul>
        <ul className="row-item">
          <TokenInfoLayoutItem title="Miracle Prize Pool" data={fund?.["KODA Tournament Prize"]} />
          <TokenInfoLayoutItem title="Miracle Club Pool" data={fund?.["KODA Tournament Club reward"]} />
          <TokenInfoLayoutItem title="Reserve" data={fund?.["KODA Reserve"]} />
        </ul>
        <ul className="row-item">
          <TokenInfoLayoutItem title="DAO" data={fund?.["KODA DAO"]} />
          {!isMobile ?
          <>
            <li></li>
            <li></li>
          </> : null}
        </ul>
      </div>
      <p className="container-box__title">LATEST TRANSFERS</p>
      <div className="foundation__inner transfers">
        <FoundationLayoutTable list={list} loading={loading} scanLink={scanLink} />
      </div>
    </div>)
}

function Home() {
  const nav = useNavigate();
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [playList, setPlayList] = useState([]);
  const [txList, setTxList] = useState([]);
  const [summary, setSummary] = useState([]);
  const ws = useRef(null);

  const getMainSummary = async () => {
    try {
      const res = await getMainSummaryAPI()
      setSummary(res)
      // console.log("getMainSummaryAPI res: ", res)
    }
    catch(e) {
      console.error("e: ", e)
    }
    finally {
    }
  }
  const getLastEventList = async () => {
    try {
      const res = await getLastEventListAPI()
      setPlayList(res.PLAY);
      setTxList(res.ERC20);
    }
    catch(e) {
      console.error("e: ", e)
    }
  }

  useEffect(() => {
    getMainSummary()
    if (!playList.length && !txList.length) {
      getLastEventList()
    }
    const handleWebSocketMessage = (event) => {
      if (event.data !== "connect2") {
        const newData = JSON.parse(event.data);

        // 새로운 데이터를 추가할 때마다 애니메이션 적용
        newData.PLAY.forEach((item, index) => {
          // console.log("ID", item.ID, item.ID !== 0)
          if (item.ID !== 0) {
            setTimeout(() => {
              setPlayList((prev) => {
                const slicedArray = prev.slice(0, 9);
                return [item, ...slicedArray];
              });
            }, index * 500);
          }
        });

        newData.ERC20.forEach((item, index) => {
          // console.log("ID", item.ID, item.ID !== 0)
          if (item.ID !== 0) {
            setTimeout(() => {
              setTxList((prev) => {
                const slicedArray = prev.slice(0, 9);
                return [item, ...slicedArray];
              });
            }, index * 500);
          }
        });
      }
    };

    ws.current = new WebSocket(constants.WEBSOCKET_SERVER_URL);
    ws.current.onopen = () => {
      // console.log('WebSocket connect');
    };
    ws.current.onclose = () => {
      console.log('WebSocket Connection Closed');
    }
    ws.current.onerror = (error) => {
      console.error("WebSocket error: ", error);
    }
    ws.current.onmessage = handleWebSocketMessage
    return () => {
      ws.current?.close();
    };
  }, []);


  const searchInputStyle = {
    id: "total-main-search",
    placeholder: "Search anything on MiracleScan",
    size: "large"
  }

  return (
    <div className="home-wrapper g-max-width">
      <div className="home-wrapper__bg"></div>
      <div className="home-wrapper__head">
        <img className="logo" src={MainLogoImg} alt="main logo img" />
        <SearchInput {...searchInputStyle} />
      </div>
      <div className="home-inner">
        <div className="container-box top">
          <div className="top__inner">
            <ul className="double">
              <li className="price">
                <div className="price__title">
                  <img src={MPTsymbol} alt="MPT symbol img" />
                  <p>MPT PRICE</p>
                </div>
                <div className="price__inner">
                  <div className="price">
                    <p>${summary?.priceUsd ? toNumberFormat(summary.priceUsd, 2) : "-"}</p>
                    <span className={`${summary?.price24H > 0 ? 'plus' : summary?.price24H < 0 ? 'minus' : 'normal'}`}>{summary?.price24H ? `${summary?.price24H > 0 ? '+' : ''}${toNumberFormat(summary.price24H, 2)}%` : ""}</span>
                  </div>
                  <div className="conversion">
                    <p>{summary?.priceBtc ? toNumberFormat(summary.priceBtc, 8) : "-"} BTC</p>
                    <p><span className={`${summary?.price24H > 0 ? 'plus' : summary?.price24H < 0 ? 'minus' : 'normal'}`}>{summary?.price24H ? `(${summary?.price24H > 0 ? '+' : ''}${toNumberFormat(summary.price24H, 2)}%)` : ""}</span></p>
                  </div>
                </div>
              </li>
              <li className="supply">
                <div className="supply__title">
                  <p>CIRCULATING SUPPLY</p>
                  {/* <TooltipHover width={230} content="This amount refers to all mainnet transactions that have taken place on the primary network (X, P, C chains) and on all subnets">
                    <div className="info-icon"></div>
                  </TooltipHover> */}
                </div>
                <div className="supply__inner">
                  <p>{summary?.circulatingSupply ? insertComma(Math.floor(summary.circulatingSupply)) : "-"}</p>
                </div>
              </li>
            </ul>
            <ul className="double">
              <li className="desc">
                <p>TRANSACTIONS</p>
                <p>{summary?.tx_count ? insertComma(summary.tx_count) : "-"}</p>
              </li>
              <li className="desc">
                <p>MARKET CAP</p>
                <p>${summary?.marketCap ? insertComma(summary.marketCap) : "-"}</p>
              </li>
            </ul>
            <div className="solo chart">
              <p className="chart__title">DAILY TRANSACTIONS</p>
              <DaliyTransactionsChart />
            </div>
          </div>
          <BaseButton onClick={() => nav('/txs')} label="View all Transactions" />
        </div>
        <FoundationLayout />
        <div className="container-box center">
          <div className="center__inner">
            <DistributionVolumeChart />
          </div>
        </div>
        <EcosystemTokenChart />
        <div className="row">
          <div className="container-box foot">
            <div className="foot__inner">
              <p className="container-box__title">LATEST PLAY</p>
              <LatestPlay data={playList} isMobile={isMobile} />
            </div>
            <BaseButton onClick={() => nav('/plays')} label="View all Plays" />
          </div>
          <div className="container-box foot">
            <div className="foot__inner">
              <p className="container-box__title">LATEST TRANSACTIONS</p>
              <LatestTransactions data={txList} isMobile={isMobile} />
            </div>
            <BaseButton onClick={() => nav('/txs')} label="View all Transactions" />
          </div>
        </div>
        <div className="container-box support">
          <div className="support__inner">
            <div className="box">
              <p>MIRACLE PLAY IS CURRENTLY LIVE ON</p>
              <ul>
                <li><img src={SupportLogo7} alt="Support Logo" /></li>
                <li><img src={SupportLogo6} alt="Support Logo" /></li>
                <li><img src={SupportLogo5} alt="Support Logo" /></li>
                <li><img src={SupportLogo1} alt="Support Logo" /></li>
                <li><img src={SupportLogo8} alt="Support Logo" /></li>
                <li><img src={SupportLogo9} alt="Support Logo" /></li>
              </ul>
            </div>
            <div className="box">
              <p>MIRACLE PLAY WILL SUPPORT SOON</p>
              <ul>
                <li><img src={SupportLogo2} alt="Support Logo" /></li>
                <li><img src={SupportLogo4} alt="Support Logo" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
