import { apiClient } from 'utils/axios';
import axios from 'axios';

export const getSearchAPI = async (key) => {
  return new Promise((resolve, reject) => {
    const source = axios.CancelToken.source()

    const url = `/search?key=${key}`
    apiClient.get(url, {
      cancelToken: source.token // 요청에 취소 토큰 추가
    })
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        console.log('Request canceled', e.message);
      } else {
        reject(e);
      }
    })
    const cancelRequest = () => {
      source.cancel('Request canceled by the user.');
    };

    resolve(cancelRequest);
  })
}

export const getChainExplorerUrlsAPI = async () => {
  return new Promise((resolve, reject) => {
    const url = `/scanList`
    apiClient.get(url)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}
