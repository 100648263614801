
export const insertComma = (balance, decimal_places) => {
  if (!balance || balance.toString().length === 0) {
    if (balance === 0) {
      return "0";
    }
    return "";
  }
  var ls = balance.toString().split(".");
  var list = [];
  for (var i = 0; i < ls[0].length; i++) {
    list.push(ls[0][i]);
    if ((ls[0].length - i - 1) % 3 === 0 && i !== ls[0].length - 1) {
      list.push(",");
    }
  }
  if (ls.length === 1) {
    return list.join("");
  } else {
    if (!decimal_places || isNaN(decimal_places)) {
      return list.join("") + "." + ls[1];
    } else {
      var dp = parseInt(decimal_places);
      if (ls[1].length < dp) {
        return list.join("") + "." + ls[1];
      } else {
        return list.join("") + "." + ls[1].substr(0, dp);
      }
    }
  }
}
