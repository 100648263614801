import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

export default function CopyItem({label, value, styled, path, open, data}) {
  const nav = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShow = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  };

  const move = () => {
    if (path) {
      nav(path, { state: data });
    } else if (open) {
      window.open(open);
    }
  }

  return (
    <div className={`copy-item-wrapper ${styled ? styled : ''}`}>
      <p onClick={move}>{label ?? value}</p>
      <div className="tooltip-wrapper">
        <div className="tooltip-inner">
          <CopyToClipboard text={value}
          onCopy={() => handleShow()}>
            <div className="copy-icon"></div>
          </CopyToClipboard>
        </div>
        {showTooltip && (
          <div className="tooltip-item">
            <div className="tooltip-item__inner" style={{ width: 45 }}>
              <p>Copied</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}