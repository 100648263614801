import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Button } from '@mui/base/Button';
import CustomTooltip from './CustomTooltip';
import { formatNumber, formatDate } from 'utils/chartDataFormat';
import { insertComma } from 'utils/insertComma';
import { getEcosystemTokenUsageAPI } from 'api/pages';
import { CircularProgress } from '@mui/material';
import { toNumberFormat } from 'utils/toNumberFormat';
import TooltipHover from 'components/ui/TooltipHover'
import { getDateTimezoneEn, timeBefore } from 'utils/timeFormat';
import { concatAddress } from 'utils/concat';
import CopyItem from 'components/ui/CopyItem';
import constants from 'constants';

function LayoutTable({list, loading}) {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const tableHead = [
    "symbol",
    "age",
    "hash",
    "from-to",
    "amount"
  ]

  return (
    <div className="table-container managed-tx-list">
      {isMobile ? <div className="m-table-inner">
      {loading ? <div className="m-table-inner__loading"><CircularProgress size={50} color="inherit" /></div> : list?.length ? list.slice(0, 5).map((body, index) => {
          return <div key={index} className="m-table-inner__item">
            <div className="item-head">
              {body?.symbol ?
              <div className="item-head__symbol">
                <img src={require(`assets/Symbol/${body.symbol}.png`)} alt={`${body.symbol}} symbol icon`} />
                <p style={{ color: body.symbol === "BPT" ? "#BA3266" : body.symbol === "MLGE" ? "#28D029" : "#fff" }}>{body.symbol}</p>
              </div> : null}
            </div>
            <ul className="item-list">
              <li>
                <p>HASH</p>
                <span onClick={() => window.open((`${body?.scan_url}tx/${body.tx_hash}`))} style={{cursor: 'pointer'}}>{body?.tx_hash ? concatAddress(body.tx_hash) : '-'}</span>
              </li>
              <li>
                <p>FROM-TO</p>
                <div className="fromTo">
                  <span onClick={() => window.open(`${body?.scan_url}address/${body.from}`)} style={{cursor: 'pointer'}}>{body?.from ? body.from : '-'}</span>
                  <div className="next-icon"></div>
                  <span onClick={() => window.open(`${body?.scan_url}address/${body.to}`)} style={{cursor: 'pointer'}}>{body?.to ? body.to : '-'}</span>
                </div>
              </li>
            </ul>
            <ul className="item-foot">
              <li>
                <p className="first">{body?.created_at ? timeBefore(new Date(body.created_at).getTime()) : '-'}</p>
                <span>{body?.created_at ? getDateTimezoneEn(new Date(body.created_at).getTime()) : '-'}</span>
              </li>
              <li>
                <span className="first">Amount</span>
                <div className="fee"><p>{body?.amount ? toNumberFormat(body.amount, 2) : "-"}</p><span>{body?.symbol}</span></div>
              </li>
            </ul>
          </div>
        }) : null}
      </div>
      : <table className="table-inner">
        <colgroup>
          {tableHead.map((head, index) => {
            return <col key={index} className={head} />;
          })}
        </colgroup>
        <thead className="table-inner__head">
          <tr>{tableHead.map((head, index) => {
            return <th key={index} className={head}><p>{head}</p></th>
          })}</tr>
        </thead>
        {loading ?
        <tbody className="table-inner__loading">
          <tr className="inner"><td className="inner__progress"><CircularProgress size={60} color="inherit" /></td><td></td></tr>
          {[...Array(5)].map((_, index) => (<tr key={index}><td></td><td></td><td></td><td></td><td></td><td></td></tr>))}
        </tbody> :
        <tbody className="table-inner__body">
          {list?.length ? list.slice(0, 5).map((body, index) => {
            return <tr key={index}>
              <td className="str">{body?.symbol ?
                <div className="symbol-wrapper">
                  <img src={require(`assets/Symbol/${body.symbol}.png`)} alt={`${body.symbol} symbol icon`} />
                  <p style={{ color: body.symbol === "BPT" ? "#BA3266" : body.symbol === "MLGE" ? "#28D029" : "#fff" }}>{body.symbol}</p>
                </div> : null}
              </td>
              <td className="column">
                <div className="column__inner">
                  <p>{body?.created_at ? timeBefore(new Date(body.created_at).getTime()) : '-'}</p>
                  <span>{body?.created_at ? getDateTimezoneEn(new Date(body.created_at).getTime()) : '-'}</span>
                </div>
              </td>
              <td className="concat">{body?.tx_hash ? <CopyItem data={body} open={`${body?.scan_url}tx/${body.tx_hash}`} value={body.tx_hash} styled="start" /> : '-'}</td>
              <td className="fromTo">
                <div className="fromTo__inner">
                  {body?.from ? <CopyItem open={(`${body?.scan_url}address/${body.from}`)} label={concatAddress(body.from)} value={body.from} styled="start" /> : '-'}
                  <div className="next-icon"></div>
                  {body?.to ? <CopyItem open={(`${body?.scan_url}address/${body.to}`)} label={concatAddress(body.to)} value={body.to} styled="start" /> : '-'}
                </div>
              </td>
              <td className="two"><div className="two__inner"><p>{body?.amount ? toNumberFormat(body.amount, 2) : "-"}</p><span>{body?.symbol}</span></div></td>
            </tr>
          }) : null}
        </tbody>}
      </table>}
    </div>
  )
}

function TokenInfoLayoutItem({title, data, tooltipDesc, symbol}) {
  return (
    <li className={!data ? "hide" : ""}>
      <>
        <div className="title">
          <p>{title ? title : "-"} <span style={{ color: symbol === "BPT" ? "#BA3266" : symbol === "MLGE" ? "#28D029" : "#fff" }}>{symbol ? symbol : ""}</span></p>
          {tooltipDesc ? <TooltipHover width={100} content={tooltipDesc}>
            <div className="info-icon"></div>
          </TooltipHover> : null}
        </div>
        <div className="inner">
          <p>{data?.Balance ? toNumberFormat(data.Balance, 2) : typeof data === 'number' ? toNumberFormat(data, 2) : "0"} <span style={{ color: symbol === "BPT" ? "#BA3266" : symbol === "MLGE" ? "#28D029" : "#fff" }}>{symbol ? symbol : ""}</span></p>
        </div>
      </>
    </li>
  )
}

function EcosystemTokenChart({data, loading}) {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [formattedData, setFormattedData] = useState([]);
  const [period, setPeriod] = useState(7);
  const periodList = {
    "7D": 7,
    "30D": 30,
    "90D": 90,
    "1Y": 365,
  }

  const setPeriodFormattedData = (date) => {
    const list = data.slice(-date)
    const result = list.filter(el => {
      return Object.values(el).every(value => value !== null);
    })
    setFormattedData(result)
  }

  const changeChartPeriod = async (value) => {
    if (loading || period === value) return;
    setPeriod(value)
    setPeriodFormattedData(value)
  }

  useEffect(() => {
    setPeriodFormattedData(period);
  }, [data])

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="chart-header__default">
          <ul className="chart-select">
            {Object.entries(periodList).map(([key, value], index) => {
              return <li key={index}>
              <Button onClick={() => changeChartPeriod(value)} disabled={loading}
                className={`small-btn ${period === value ? 'isActive' : ''}`}>
                <p>{key}</p>
              </Button>
              </li>
            })}
          </ul>
        </div>
      </div>
      {!loading && !formattedData.length ?
        <div className="chart-nodata">
          <div className="chart-nodata__inner">
            <p>NO DATA</p>
            <span>No data, please try again later</span>
          </div>
        </div> :
        <>
        <div className="chart-box large-chart-box">
          {loading ? <div className="progress-wrapper"><CircularProgress size={55} color="inherit" /></div> :formattedData.length ?
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={formattedData}
              className="main-value-chart"
              margin={{
                top: 0,
                right: 20,
                bottom: 10,
                left: isMobile ? -15 : 40,
              }}
              >
              <CartesianGrid
                horizontal={true} // 수평선
                vertical={true} // 수직선
                stroke="#51515E"
                strokeDasharray="2 2"
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="bottom"
                iconType="plainline"
                iconSize={30}
                wrapperStyle={{ fontSize: 11, bottom: 0, paddingTop: 10, left: '50%', transform: 'translateX(-50%)' }} />
              <Line type="linear" dataKey="bpt" name="BPT" strokeWidth={2} stroke="#BA3266" dot={false}
                activeDot={{ r: 2 }} />
              <Line type="linear" dataKey="mlge" name="MLGE" strokeWidth={2} stroke="#28D029" dot={false}
                activeDot={{ r: 2 }} />
              <Line type="linear" dataKey="mpt" name="MPT" strokeWidth={2} stroke="#fff" dot={false}
                activeDot={{ r: 2 }} />
              {/* x축 중심선 */}
              <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={formatDate} />
              {/* y축 중심선 */}
              <YAxis tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile? formatNumber : insertComma} />
            </LineChart>
          </ResponsiveContainer> : null}
        </div>
      </>}
    </div>
  )
}

export default function EcosystemTokenLayout() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastTxs, setLastTxs] = useState([]);
  const [dailyTokenValue, setDailyTokenValue] = useState({})
  const [totalTokenValue, setTotalTokenValue] = useState({})

  const getEcosystemTokenUsage = async () => {
    setLoading(true)
    try {
      const res = await getEcosystemTokenUsageAPI();
      const result = res?.All === null ? [] : res?.All;

      const dailyValue = res?.Daily[0] || {};
      setDailyTokenValue(dailyValue);

      const lastTxsList = res?.lastTxs || [];;
      setLastTxs(lastTxsList)

      if (result.length > 0) {
        setData(result);
        const totalValue = result?.[result?.length - 1];
        setTotalTokenValue(totalValue);
      }
    }
    catch(e) {
      console.log("e: ", e)
    }
    finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    getEcosystemTokenUsage();
  }, [])

  return (
    <div className="container-box foundation">
      <p className="container-box__title">Miracle Play Ecosystem Token Usage</p>
      <div className="foundation__inner address">
        <ul className="row-item">
          <TokenInfoLayoutItem symbol="MPT" tooltipDesc="Last 24 Hours" title="DAILY" data={dailyTokenValue?.mpt_adjusted_value} />
          <TokenInfoLayoutItem symbol="BPT" tooltipDesc="Last 24 Hours" title="DAILY" data={dailyTokenValue?.bpt_adjusted_value} />
          <TokenInfoLayoutItem symbol="MLGE" tooltipDesc="Last 24 Hours" title="DAILY" data={dailyTokenValue?.mlge_adjusted_value} />
        </ul>
        <ul className="row-item">
          <TokenInfoLayoutItem symbol="MPT" title="TOTAL" data={totalTokenValue?.mpt} />
          <TokenInfoLayoutItem symbol="BPT" title="TOTAL" data={totalTokenValue?.bpt} />
          <TokenInfoLayoutItem symbol="MLGE" title="TOTAL" data={totalTokenValue?.mlge} />
        </ul>
      </div>
      <EcosystemTokenChart data={data} loading={loading} />
      <div className="table-wrapper-head">
        <p className="container-box__title">LATEST TRANSFERS</p>
        <div className="foundation__inner transfers">
          <LayoutTable list={lastTxs} loading={loading} />
        </div>
      </div>
    </div>
  )
}