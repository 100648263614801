import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, ComposedChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Button } from '@mui/base/Button';
import CustomTooltip from './CustomTooltip';
import { formatNumber, formatDate } from 'utils/chartDataFormat';
import { insertComma } from 'utils/insertComma';
import Select from '../ui/Select'
import { getGameListAPI, getHistoricalActivityChartAPI } from 'api/pages'
import { CircularProgress } from '@mui/material';

function truncateToFourDecimals(num) {
  return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
}

const chainList = [
  {
    id: 137,
    title: "Polygon"
  },
  {
    id: 43114,
    title: "Avalanche"
  },
  {
    id: 8453,
    title: "Base"
  },
  {
    id: 204,
    title: "OpBNB",
  },
  {
    id: 42161,
    title: "Arbitrum",
  },
  {
    id: 37,
    title: "XPLA",
  },
]

function RewardsChart({loading, data, originData, period}) {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [token, setToken] = useState("All");
  const [tokenList, setTokenList] = useState(null);
  const tokenSelectData = { select: token, setSelect: setToken, selectList: tokenList, id: "token-select" }

  const setTokens = () => {
    const tokenKeySet = new Set();
    originData
    .filter(item => item.rewards)
    .map((item) => {
      for (const key of Object.keys(item.rewards)) {
        if (key !== "unknown") {
          tokenKeySet.add(key);
        }
      }
      return tokenKeySet;
    });

    const tokens = Array.from(tokenKeySet);
    setTokenList(["All", ...tokens]);
  }

  const getFormattedRewards = (item, token) => {
    const rewards = {};
    for (const [key, value] of Object.entries(item.rewards)) {
      if (token === "MLGE" && key === "MLGE") {
        rewards[key] = value.balance;
      } else if ((token === "All" || (token !== "All" && key === token)) && value.amt !== null) {
        rewards[key] = value.amt;
      }
    }
    return rewards;
  };

  const formattedData = data
    .filter(item => item.rewards && (token === "All" || (token !== "All" && item.rewards[token])))
    .map(item => ({
      date: item.date,
      ...getFormattedRewards(item, token),
    })) || [];

  useEffect(() => {
    if (originData.length) {
      setTokens()
    }
  }, [originData])

  const renderBars = () => {
    const color = data?.find(item => item?.rewards[token])?.rewards[token]?.color || '#fff';
    return <Bar key="date" dataKey={token} fill={color} />;
  };

  const BalanceTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload[0]?.payload?.date ?
            <p className="custom-tooltip__date">
              {payload[0]?.payload?.date}
            </p> : null}
          <ul>
            {payload.map((item, index) => {
              const balance = data?.find(d => d?.date === item.payload.date)?.rewards[item.name].balance;
              const value = data?.find(d => d?.date === item.payload.date)?.rewards[item.name].amt;
              return (
                <li key={`item-${index}`}><strong style={{ color: item.color }}>{item.name}:</strong> {insertComma(balance)}(${insertComma(truncateToFourDecimals(token === "MLGE" ? value : item.value))})</li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderAllBars = () => {
    const keys = [...new Set(formattedData.flatMap(item => Object.keys(item).filter(key => key !== "date")))];
    return (
      <>
        {keys.map(key => {
          const color = data?.find(d => d?.rewards[key])?.rewards[key]?.color || '#fff';
          return (
            <Bar key={key} dataKey={key} fill={color} stackId="a" />
          );
        })}
      </>
    )
  };

  if (!originData?.length) return null;

  return (
    <div style={{ marginTop: '30px' }}>
      <div className="chart-header">
        <div className="chart-header__default">
          <div className="title">
            <p>Prize</p>
          </div>
        </div>
        {(token && tokenList?.length) ?
        <div className="chart-header__option solo">
          <div className="select-wrapper">
            <p className="select-title">Token</p>
            <Select {...tokenSelectData} />
          </div>
        </div> : null}
      </div>
      {!loading && !formattedData.length ?
        <div className="chart-nodata">
          <div className="chart-nodata__inner">
            {token ? <p>No {token} rewards for {period ? period : ""}{period !== 365 ? 'd' : period === 365 ? 'y' : ''}</p> : null}
          </div>
        </div> :
      <div className="chart-box large-chart-box no-legend child-box last-padding">
        {loading ? <div className="progress-wrapper"><CircularProgress size={55} color="inherit" /></div> : (formattedData.length) ?

        <ResponsiveContainer width="100%" height="100%">
        <BarChart data={formattedData}
          className="main-value-chart"
          margin={{
            top: 0,
            right: 20,
            bottom: 10,
            left: isMobile ? -15 : 40,
          }}
          >
          <CartesianGrid
            horizontal={true} // 수평선
            vertical={true} // 수직선
            stroke="#51515E"
            strokeDasharray="2 2"
          />
          <Tooltip content={<BalanceTooltip />} cursor={{fill: 'rgba(81, 81, 94, 0.2)'}} />
          <Legend
            verticalAlign="bottom"
            iconType="square"
            iconSize={12}
            wrapperStyle={{ fontSize: 11, bottom: 0, paddingTop: 10, left: '50%', transform: 'translateX(-50%)' }} />
          <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={formatDate} />

          {token === "All" ? renderAllBars() : renderBars()}

          <YAxis tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile ? formatNumber : insertComma} />
        </BarChart>
        </ResponsiveContainer>: null}
      </div>}
    </div>
  )
}

export default function HistoricalActivityChart() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [period, setPeriod] = useState(7);
  const [chain, setChain] = useState(null);
  const [game, setGame] = useState(null);
  const [gameList, setGameList] = useState([]);
  const [allGameList, setAllGameList] = useState([]);
  const periodList = {
    "7D": 7,
    "30D": 30,
    "90D": 90,
    "1Y": 365,
  }

  const setPeriodFormattedData = (date) => {
    const list = data.slice(-date)
    setFormattedData(list)
  }

  const getHistoricalActivityChart = async () => {
    if (!chain?.title || !game) return;
    setLoading(true)
    try {
      const res = await getHistoricalActivityChartAPI(chain.title.toUpperCase(), game);
      const result = res === null ? [] : res?.filter(el => {
        return Object.values(el).every(value => value !== null);
      }).map(el => {
        return {
          date: el.date,
          hosts: el.hosts,
          participant: el.participant,
          reward: el.reward,
          rewards: el.rewards !== null ? JSON.parse(el.rewards) : []
        }
      })
      setData(result);
    } catch(e) {
      console.log("e: ", e)
    } finally {
      setLoading(false)
    }
  }

  const getAllGameList = async () => {
    try {
      const res = await getGameListAPI();
      // console.log("res", res)
      setAllGameList(res);
    } catch(e) {
      console.error(e)
    }
  }

  const setChainGameList = async () => {
    if (!chain?.title || !allGameList?.length) return;
    try {
      const items = allGameList?.filter(el => {
        return el?.name.toLowerCase() === chain.title.toLowerCase();
      })?.map(el => el?.first_letter);
      setGameList(["All", ...items]);
      setGame("All")
      getHistoricalActivityChart();
    } catch(e) {
      console.error(e)
    }
  }

  const changeChartPeriod = async (value) => {
    if (loading || period === value) return;
    setPeriod(value)
    setPeriodFormattedData(value)
  }

  const chainSelectData = { select: chain, setSelect: setChain, selectList: chainList, id: "chain-select" }
  const gameSelectData = { select: game, setSelect: setGame, selectList: gameList, id: "game-select" }

  useEffect(() => {
    getAllGameList();
    setChain(chainList[0]);
  }, [])

  useEffect(() => {
    setPeriodFormattedData(period);
  }, [data])

  useEffect(() => {
    setChainGameList();
  }, [chain, allGameList])

  useEffect(() => {
    getHistoricalActivityChart();
  }, [game])


  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="chart-header__default">
          <div className="title">
            <p>HISTORICAL ACTIVITY</p>
            <span>Reference Time UTC+0</span>
          </div>
          <ul className="chart-select">
            {Object.entries(periodList).map(([key, value], index) => {
              return <li key={index}>
              <Button onClick={() => changeChartPeriod(value)} disabled={loading}
                className={`small-btn ${period === value ? 'isActive' : ''}`}>
                <p>{key}</p>
              </Button>
              </li>
            })}
          </ul>
        </div>
        <div className="chart-header__option">
          <div className="select-wrapper">
            <p className="select-title">Chain</p>
            {chain?.title ? <Select {...chainSelectData} /> : null}
          </div>
          {gameList?.length ?
            <div className="select-wrapper">
              <p className="select-title">Games</p>
              {game ? <Select {...gameSelectData} /> : null}
            </div>
          : null}
        </div>
      </div>
      {!loading && !formattedData.length ?
        <div className="chart-nodata">
          <div className="chart-nodata__inner">
            <p>NO DATA</p>
            <span>No data, please try again later</span>
          </div>
        </div> :
        <>
          <div className="chart-box large-chart-box no-legend child-box">
            {loading ? <div className="progress-wrapper"><CircularProgress size={55} color="inherit" /></div> :
            formattedData.length ?
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={formattedData}
                className="main-value-chart"
                margin={{
                  top: 0,
                  right: 20,
                  bottom: 10,
                  left: isMobile ? -15 : 40,
                }}
              >
                <CartesianGrid
                  horizontal={true} // 수평선
                  vertical={true} // 수직선
                  stroke="#51515E"
                  strokeDasharray="2 2"
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="bottom"
                  iconType="square"
                  iconSize={12}
                  wrapperStyle={{ fontSize: 11, bottom: 0, paddingTop: 10, left: '50%', transform: 'translateX(-50%)' }} />
                <Bar yAxisId="right" dataKey="hosts" name="Hosts" barSize={isMobile ? 16 : 24} fill="#9E90F2" />
                <Line yAxisId="left" type="linear" dataKey="participant" name="Participant" strokeWidth={2} stroke="#D2226C" dot={false}
                  activeDot={{ r: 2 }} />
                <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={formatDate} />
                <YAxis allowDataOverflow domain={[0, 'auto']} yAxisId="left" tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile ? formatNumber : insertComma} />
                <YAxis allowDataOverflow domain={[0, 'auto']} yAxisId="right" orientation="right" tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile ? formatNumber : insertComma} />
              </ComposedChart>
            </ResponsiveContainer> : null}
          </div>

        </>}
        <RewardsChart period={period} originData={data} data={formattedData} loading={loading} />
    </div>
  )
}