import { useState, useEffect, useRef } from 'react';
import { OutlinedInput } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { getSearchAPI } from 'api/common'
import constants from 'constants';
import LinkIcon from 'assets/Icon/link.svg'

import { apiClient } from 'utils/axios';
import axios from 'axios';

const chain = constants.chain
let cancelRequest; // 전역 변수로 이전 요청을 취소하는 함수를 저장합니다.

export default function FormInput(props) {
  const { id, placeholder, size } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [list, setList] = useState([]);
  const [time, setTime] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (e)=> {
    const gap = e.timeStamp - time;
    const value = e.target.value;
    setKeyword(value);
    if (!value) {
      setList([]);
      clearTimeout(delay);
      setLoading(false)
      return;
    }
    if (value.length >= 6) {
      setLoading(true)
      clearTimeout(delay);

      // 이전 요청 취소
      if (cancelRequest) {
        cancelRequest();
      }

      // 새로운 요청 생성
      const source = axios.CancelToken.source()
      cancelRequest = source.cancel;
      setDelay(setTimeout(() => {
        getSearch(value, source.token);
      }, 1000))

      if (time && gap >= 5000) {
        clearTimeout(delay);
        setDelay(setTimeout(() => {
          getSearch(value, source.token);
        }, 2000))
      }
    }

    setTime(e.timeStamp);
  }

  const getSearch = async (value, cancelToken) => {
    setLoading(true)
    try {
      const res = await apiClient.get(`/search?key=${value}`, { cancelToken });
      setList(res.data);
      setLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log('Request canceled', e.message);
      } else {
        console.log("getGameListAPI e: ", e);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!keyword) {
      setList([]);
      return;
    }
  }, [keyword])

  const handleOutsideClick = () => {
    setShowSearch(false);
    setKeyword(null)
  };

  const useOutsideClick = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [callback, ref]);

    return ref;
  };
  const ref = useOutsideClick(handleOutsideClick);

  return (
    <div ref={ref} className={`search-wrapper ${size ? size : ''}`}>
      <div className={`search-input-wrapper ${size ? size : ''}`}>
        <OutlinedInput
          className={id}
          autoComplete="off"
          placeholder={placeholder}
          name={id}
          id={id}
          type={"text"}
          value={keyword || ''}
          onChange={onChange}
          // onBlur={onBlur}
          onFocus={() => setShowSearch(true)}
          endAdornment={<div className="search-icon"></div>}
          />
        {showSearch ? <div className="search-details">
          <div className="search-details__inner">
            {loading ? (
              <div className="loading-wrap"><CircularProgress size={30} color="inherit" /></div>
              ) : list?.length ? (
                <div className="result">
                  <ul className="result__list">
                    {list.map((item, index) => (
                      <li key={index}>
                        {item.chain_id ? <img className="symbol" src={require(`assets/Symbol/${chain[item.chain_id]}.svg`)} alt={`${chain[item.chain_id]}} symbol icon`} /> : null}
                        <p>{item.result}</p>
                        <img className="link" onClick={() => {window.open(item.url)}} src={LinkIcon} alt="link icon" />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : keyword?.length ? (
                <div className="infos-wrap"><p>No results found.</p></div>
              ) : (
                <div className="infos-wrap"><p>Search requires at least 6 characters.</p></div>
              )
            }
          </div> </div> : null}
      </div>
    </div>
  )
}