const constants = {
  WEBSOCKET_SERVER_URL: 'wss://miraclescanapi.meverselabs.io/ws2',
  chain: {
    "POLYGON": 137,
    "AVALANCHE": 43114,
    "BASE": 8453,
    "OPBNB": 204,
    "ARBITRUM": 42161,
    "XPLA": 37,
    137: "POLYGON",
    43114: "AVALANCHE",
    8453: "BASE",
    204: "OPBNB",
    42161: "ARBITRUM",
    37: "XPLA",
  },
  token: {
    137: "MATIC",
    204: "BNB",
    43114: "AVAX",
    8453: "BASE",
    42161: "ARB",
    37: "XPLA",
  }
}

export default constants;