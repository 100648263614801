import { configureStore } from '@reduxjs/toolkit';
import chain from './modules/chain';
import ui from './modules/ui';
import components from './modules/components';
const store = configureStore({
  reducer:{
    chain,
    ui,
    components
  }
});

export default store;