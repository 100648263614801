import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { selectModal, closeModal } from 'store/modules/components';
import Dialog from '@mui/material/Dialog';

export default function Language() {
  const dispatch = useDispatch();
  const { modalType, isOpen } = useSelector(selectModal);
  const handleClose = () => { dispatch(closeModal()); };

  return (
    <Dialog
      open={modalType === "Language" && isOpen}
      onClose={handleClose}
      className="base-dialog-wrapper select-small"
    >
      <div className="base-dialog-inner">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title"></p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-banner-body language">
        </div>
      </div>
    </Dialog>
  )
}