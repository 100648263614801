import { insertComma } from 'utils/insertComma';

function CustomTooltip({ payload, active }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0]?.payload?.date ?
          <p className="custom-tooltip__date">
            {payload[0]?.payload?.date}
          </p> : null}
        <ul>
          {payload.map((item, index) => {
            return (
              <li className='small' key={index}>{`${item.name} : ${insertComma(item.value)}`}</li>
              // payload.length > 1 ?
              // <li key={index}>{`${item.name} : ${insertComma(item.value)}`}</li> :
              // <li key={index}>{insertComma(item.value)}</li>
            )
          })}
        </ul>
      </div>
    );
  }
  return null;
}

export default CustomTooltip;