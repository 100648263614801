import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import CustomTooltip from './CustomTooltip';
import { formatNumber, formatDate } from 'utils/chartDataFormat';
import { insertComma } from 'utils/insertComma';
import { getDailyTransactionChartAPI } from 'api/pages';

export default function DaliyTransactionsChart() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [data, setData] = useState([]);

  const getDistributionVolume = async () => {
    try {
      const res = await getDailyTransactionChartAPI();
      const result = (res === null || !res?.length) ? [] : res.slice(-7)
      // console.log(result)
      setData(result);
    }
    catch(e) {
      console.log("e: ", e)
    }
  }

  useEffect(() => {
    getDistributionVolume();
  }, [])

  return (
    <>
      <div className="chart-box small-chart-box">
        {data.length ?
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}
            className="main-value-chart"
            margin={{
              top: 30,
              right: 20,
              bottom: 0,
              left: isMobile ? -20 : 0,
            }}
            >
            <CartesianGrid
              horizontal={true} // 수평선
              vertical={true} // 수직선
              stroke="#51515E"
              strokeDasharray="2 2"
            />
            <Tooltip content={<CustomTooltip />} />
            <Line type="linear" dataKey="total" strokeWidth={1} stroke="#fff" dot={false}
              activeDot={{ r: 1.5 }} name="Total" />
            <Line type="linear" dataKey="POLYGON" strokeWidth={1} stroke="#823BDE" dot={false}
              activeDot={{ r: 1.5 }} name="Polygon Chain" />
            <Line type="linear" dataKey="AVALANCHE" strokeWidth={1} stroke="#E84142" dot={false}
              activeDot={{ r: 1.5 }} name="Avalanche Chain" />
            <Line type="linear" dataKey="BASE" strokeWidth={1} stroke="#0057FF" dot={false}
              activeDot={{ r: 1.5 }} name="Base Chain" />
            <Line type="linear" dataKey="OPBNB" strokeWidth={1} stroke="rgb(240, 185, 11)" dot={false}
              activeDot={{ r: 1.5 }} name="opBNB Chain" />
            <Line type="linear" dataKey="ARBITRUM" strokeWidth={1} stroke="rgb(18 170 255)" dot={false}
              activeDot={{ r: 1.5 }} name="Arbitrum Chain" />
            <Line type="linear" dataKey="XPLA" strokeWidth={1} stroke="#01b2ff" dot={false}
              activeDot={{ r: 1.5 }} name="Xpla Chain" />
            {/* x축 중심선 */}
            <XAxis dataKey="date" interval={0} xAxisId={0} hide />
            <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} xAxisId={1} minTickGap={20} tickFormatter={formatDate} />
            {/* y축 중심선 */}
            <YAxis tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile? formatNumber : insertComma}
              tickCount={3} />
          </LineChart>
        </ResponsiveContainer> : null}
      </div>
    </>
  )
}