
function pad(str, len) {
  str = str.toString();
  var list = [];
  for (var i = str.length; i < len; i++) {
    list.push("0");
  }
  list.push(str);
  return list.join("");
}

export const getDateTimezoneEn = (timestamp, noTimezone, unit) => {
  if (!timestamp) {
    return "";
  }
  var dt = null
  if (unit === 's') {
    dt = new Date(timestamp * 1000);
  } else {
    dt = new Date(timestamp); // / 1000000
  }

  var ofs = - new Date().getTimezoneOffset() / 60;
  var timezone = "UTC";
  if (ofs > 0) {
    timezone += "+" + pad(ofs, 2);
  } else {
    timezone += "-" + pad(-ofs, 2);
  }
  if (noTimezone) {
    timezone = '';
  }
  return (
    dt.getFullYear() +
    "-" +
    pad(dt.getMonth() + 1, 2) +
    "-" +
    pad(dt.getDate(), 2) +
    " " +
    pad(dt.getHours(), 2) +
    ":" +
    pad(dt.getMinutes(), 2) +
    ":" +
    pad(dt.getSeconds(), 2) +
    " " +
    timezone
  );
}

export const timeBefore = (timestamp, unit) => {
  if (!timestamp) return;
  let time = null
  if (unit === 's') {
    time = new Date(timestamp * 1000);
  } else {
    time = new Date(timestamp); // / 1000000
  }
  //현재시간
  let now = new Date();
  //기준시간
  let writeDay = new Date(time);
  let ResultTime = "";
  let difference = now.getTime() - writeDay.getTime();
  difference = Math.trunc(difference / 1000);

  // 초 (밀리초)
  const seconds = 1;
  // 분
  const minute = seconds * 60;
  // 시
  const hour = minute * 60;
  // 일
  const day = hour * 24;
  //달
  const mon = day * 30;
  //년
  const year = mon * 12;

  let resultYear, resultMon, resultDay, resultHour, resultMin, resultSec;


  resultYear = Math.trunc(difference / year);
  if(resultYear > 0){
    ResultTime += resultYear+' years ';
    difference= difference - (resultYear * year);
  }
  resultMon = Math.trunc(difference / mon);
  // if(resultMon > 0){
  //   ResultTime += resultMon+' month ';
  //   difference=  difference - (resultMon * mon);
  // }
  resultDay = Math.trunc(difference / day);
  if(resultDay > 0){
    ResultTime += resultDay+' days ';
    difference = difference - (resultDay * day);
  }
  resultHour = Math.trunc(difference / hour);
  if(resultHour > 0 && resultYear <= 0){
    ResultTime += resultHour+' hrs ';
    difference = difference - (resultHour * hour);
  }
  resultMin = Math.trunc(difference / minute);
  if(resultMin > 0 && resultDay <= 0){
    ResultTime += resultMin+' mins ';
    difference = difference - (resultMin * minute);
  }
  resultSec = Math.trunc(difference / seconds);
  if(resultSec > 0 && resultMin <= 0){
    ResultTime += resultSec+' secs ';
    difference = difference - (resultSec * seconds );
  }
  if (!ResultTime) {
    return 'just before'
  } else {
    return `${ResultTime}ago`;
  }
}
