import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chainExplorerUrls: {}
};
const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    setChainExplorerUrls: (state, action) => {
      state.chainExplorerUrls = action.payload;
    },
  },
});

export default chainSlice.reducer;
export const chain = (state) => state.chain;
export const { setChainExplorerUrls } = chainSlice.actions;