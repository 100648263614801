import { useSelector } from 'react-redux';
import { selectModal } from 'store/modules/components';

import LanguageModal from 'components/modal/Language';

const MODAL_TYPES = {
  Language: "Language"
};

const MODAL_COMPONENTS = [
  {
    type: MODAL_TYPES.Language,
    component: <LanguageModal />
  },
];

export default function GlobalModal() {
  const { modalType, isOpen } = useSelector(selectModal);
  if (!isOpen) return;

  const findModal = MODAL_COMPONENTS.find((modal) => {
    return modal.type === modalType;
  });

  const renderModal = () => {
    return findModal.component;
  };
  return (
    <>
      {renderModal()}
    </>
  );
}