import { useSelector } from 'react-redux';

const useChainInfo = () => {
  const chainExplorerUrls = useSelector(state => { return state?.chain.chainExplorerUrls; });
  const setChainScanUrl = (chainType, to, type) => {
    if (!chainType || !to || !type) return;
    const chain = chainExplorerUrls?.find(item => {
      if (typeof chainType === 'number') {
        return item.ChainID === chainType
      } else {
        return item.Name === chainType
      }
    })
    return `${chain.ScanUrl}${type}/${to}`;
  }

  return { setChainScanUrl };
}

export default useChainInfo;