import { useState, useEffect, useLayoutEffect  } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Button } from '@mui/base/Button';
import CustomTooltip from './CustomTooltip';
import { formatNumber, formatDate } from 'utils/chartDataFormat';
import { insertComma } from 'utils/insertComma';
import { getDailyTransactionChartAPI } from 'api/pages';
import { CircularProgress } from '@mui/material';

export default function DaliyTransactionsChart() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [period, setPeriod] = useState(7);
  const periodList = {
    "7D": 7,
    "30D": 30,
    "90D": 90,
    "1Y": 365,
  }

  const setPeriodFormattedData = (date) => {
    const list = data.slice(-date)
    const result = list.filter(el => {
      return Object.values(el).every(value => value !== null);
    })
    setFormattedData(result)
  }

  const getDistributionVolume = async () => {
    setLoading(true)
    try {
      const res = await getDailyTransactionChartAPI();
      const result = res === null ? [] : res;
      setData(result);
    }
    catch(e) {
      console.log("e: ", e)
    }
    finally {
      setLoading(false)
    }
  }

  const changeChartPeriod = async (value) => {
    if (loading || period === value) return;
    setPeriod(value)
    setPeriodFormattedData(value)
  }

  useEffect(() => {
    setPeriodFormattedData(period);
  }, [data])


  useLayoutEffect(() => {
    getDistributionVolume(period);
  }, [])

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="chart-header__default">
          <div className="title">
            <p>DAILY TRANSACTIONS</p>
          </div>
          <ul className="chart-select">
            {Object.entries(periodList).map(([key, value], index) => {
              return <li key={index}>
              <Button onClick={() => changeChartPeriod(value)} disabled={loading}
                className={`small-btn ${period === value ? 'isActive' : ''}`}>
                <p>{key}</p>
              </Button>
              </li>
            })}
          </ul>
        </div>
      </div>
      {!loading && !formattedData.length ?
        <div className="chart-nodata">
          <div className="chart-nodata__inner">
            <p>NO DATA</p>
            <span>No data, please try again later</span>
          </div>
        </div> :
        <div className="chart-box large-chart-box">
          {loading ? <div className="progress-wrapper"><CircularProgress size={55} color="inherit" /></div> :formattedData.length ?
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={formattedData}
              className="main-value-chart"
              margin={{
                top: 0,
                right: 20,
                bottom: 20,
                left: isMobile ? -15 : 40,
              }}
              >
              <CartesianGrid
                horizontal={true} // 수평선
                vertical={true} // 수직선
                stroke="#51515E"
                strokeDasharray="2 2"
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="bottom"
                iconType="plainline"
                iconSize={30}
                wrapperStyle={{ fontSize: 11, bottom: 0, paddingTop: 10, left: '50%', transform: 'translateX(-50%)' }} />
              <Line type="linear" dataKey="total" strokeWidth={2} stroke="#fff" dot={false}
                activeDot={{ r: 2 }} name="Total" />
              <Line type="linear" dataKey="POLYGON" strokeWidth={2} stroke="#823BDE" dot={false}
                activeDot={{ r: 2 }} name="Polygon Chain" />
              <Line type="linear" dataKey="AVALANCHE" strokeWidth={2} stroke="#E84142" dot={false}
                activeDot={{ r: 2 }} name="Avalanche Chain" />
              <Line type="linear" dataKey="BASE" strokeWidth={2} stroke="#0057FF" dot={false}
                activeDot={{ r: 2 }} name="Base Chain" />
              <Line type="linear" dataKey="OPBNB" strokeWidth={2} stroke="rgb(240, 185, 11)" dot={false}
                activeDot={{ r: 2 }} name="opBNB Chain" />
              <Line type="linear" dataKey="ARBITRUM" strokeWidth={2} stroke="rgb(18 170 255)" dot={false}
                activeDot={{ r: 2 }} name="Arbitrum Chain" />
              <Line type="linear" dataKey="XPLA" strokeWidth={2} stroke="#01b2ff" dot={false}
                activeDot={{ r: 2 }} name="XPLA Chain" />
              {/* x축 중심선 */}
              <XAxis dataKey="date" tickMargin={10} tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={formatDate} />
              {/* y축 중심선 */}
              <YAxis tick={{ fontSize: 11, fill: "#fff", fontFamily: "Roboto Mono" }} tickFormatter={isMobile? formatNumber : insertComma}
                tickCount={3} />
            </LineChart>
          </ResponsiveContainer> : null}
        </div>
      }
    </div>
  )
}