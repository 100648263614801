import { useState, useEffect } from 'react';
// import TooltipHover from 'components/ui/TooltipHover'
import { insertComma } from 'utils/insertComma';
import DaliyTransactionsChart from 'components/charts/DaliyTransactionsChart';
import DistributionVolumeChart from 'components/charts/DistributionVolumeChart';
import HistoricalActivityChart from 'components/charts/HistoricalActivityChart';
import Select from 'components/ui/Select'
import { getResourcesInfoAPI, getWeeklyStateListAPI, getweeklyStateAPI, getMintedCirculatingAPI } from 'api/pages';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { formatToYYYYMMDD } from 'utils/formatToYYYYMMDD'

const datePickerFormat = "YYYY-MM-DD";
const datePickerUtils = {
    format: datePickerFormat,
    parse: (value) => dayjs(value, datePickerFormat, true).toDate(),
};

function DailyInformationMPT() {
  const [data, setData] = useState({});
  const [date, setDate] = useState(dayjs(new Date()));

  const getMintedCirculating = async (date) => {
    try {
      const res = await getMintedCirculatingAPI(date)
      if (res) setData(res)
    } catch(e) {
      console.log("e: ", e)
    }
  }

  useEffect(() => {
    const formatDate = formatToYYYYMMDD(date)
    if (formatDate) {
      getMintedCirculating(formatDate)
    }
  }, [date])

  return (
    <div className="container-box-wrapper">
      <div className="container-box-header">
        <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={datePickerUtils}>
          <div className="base-date-picker-wrapper">
            <DesktopDatePicker className="base-date-picker" label="date" format="YYYY-MM-DD" name="date"
              slotProps={{ textField: { size: 'small' },
              day: {
                sx: {
                  "&.MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#9E90F2",
                  },
                  ":not(.Mui-selected)": {
                    borderColor: "#fff"
                  },
                },
              }, }}
              value={date} onChange={(newValue) => {setDate(newValue);}} />
          </div>
        </LocalizationProvider>
      </div>
      <div className="container-box grid">
        <div className="grid__inner">
          <ul className="row-item">
            <li>
              <div className="title">
                <p>Minted MPT / Designed MPT</p>
              </div>
              <div className="inner">
              <p>{`${data?.minted_circulating ? insertComma(Math.floor(data.minted_circulating)) : "0"} / ${data?.disign_total_supply ? insertComma(Math.floor(data.disign_total_supply)) : "0"}`}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function WeeklyInformationMPT() {
  const [currentWeek, setCurrentWeek] = useState({});
  const [weekList, setWeekList] = useState([]);
  const [data, setData] = useState({});
  const chainSelectData = { select: currentWeek, setSelect: setCurrentWeek, selectList: weekList, id: "chain-select", objData: true }

  const getWeeklyStateList = async () => {
    try {
      const res = await getWeeklyStateListAPI()
      if (res) {
        setWeekList(res)
        setCurrentWeek(res[res?.length - 1])
      }
    } catch(e) {
      console.log("e: ", e)
    }
  }

  const getweeklyState = async (date) => {
    try {
      const res = await getweeklyStateAPI(date)
      if (res) setData(res)
    } catch(e) {
      console.log("e: ", e)
    }
  }

  useEffect(() => {
    getWeeklyStateList()
  }, [])

  useEffect(() => {
    const week = Object.values(currentWeek)?.[0] || null
    if (week) {
      getweeklyState(week)
    }
  }, [currentWeek])

  return (
    <div className="container-box-wrapper">
      <div className="container-box-header">
        {currentWeek ? <Select {...chainSelectData} /> : null}
      </div>
      <div className="container-box grid">
        <div className="grid__inner">
          <ul className="row-item two-row">
            <li>
              <div className="title">
                <p>Minted MPT through Weekly NFTs</p>
              </div>
              <div className="inner">
              <p>{data?.minted_mpt_from_nft ? `${insertComma(Math.floor(data.minted_mpt_from_nft))} MPT` : '0'}</p>
              </div>
            </li>
            <li>
              <div className="title">
                <p>WEEKLY CHANGE IN NFT STAKING AMOUNT</p>
              </div>
              <div className="inner">
              <p>{data?.nft_staking ? `${insertComma(Math.floor(data.nft_staking))}` : '0'}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function Information(props) {
  const { select } = props;
  const chain = select?.title;
  const [data, setData] = useState({})

  const getResourcesInfo = async () => {
    if (!chain) return;
    try {
      const res = await getResourcesInfoAPI(chain);
      if (chain === "All") {
        const totals = {
            "completed_games": 0,
            "daily": 0,
            "in_progress_games": 0,
            "monthly": 0,
            "new_applications": 0,
            "online_current_players": 0,
            "total": 0,
            "total_cumulative_players": 0,
            "weekly": 0
        };
        for (const key in Object.values(res)) {
          const obj = Object.values(res)[key];
          for (const stat in obj) {
            totals[stat] = (totals[stat] || 0) + obj[stat];
          }
        }
        setData(totals);
      } else {
        setData(res);
      }
    }
    catch(e) {
      console.log("e", e)
    }
  }

  useEffect(() => {
    getResourcesInfo()
  }, [chain])

  return (
    <div className="container-box grid">
      <div className="grid__inner">
        <ul className="row-item">
          <li>
            <div className="title">
              <p>NEW APPLICATIONS</p>
              {/* <TooltipHover width={120} content="NEW APPLICATIONS">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>{data?.new_applications ? insertComma(data.new_applications) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>IN PROGRESS GAMES</p>
              {/* <TooltipHover width={120} content="IN PROGRESS GAMES">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>{data?.in_progress_games ? insertComma(data.in_progress_games) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>COMPLETED GAMES</p>
              {/* <TooltipHover width={120} content="COMPLETED GAMES">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>{data?.completed_games ? insertComma(data.completed_games) : '0'}</p>
            </div>
          </li>
        </ul>
        <ul className="row-item">
          <li>
            <div className="title">
              <p>ONLINE CURRENT PLAYERS</p>
              {/* <TooltipHover width={120} content="ONLINE CURRENT PLAYERS">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>{data?.online_current_players ? insertComma(data.online_current_players) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>TOTAL CUMULATIVE PLAYERS</p>
              {/* <TooltipHover width={120} content="TOTAL CUMULATIVE PLAYERS">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>{data?.total_cumulative_players ? insertComma(data.total_cumulative_players) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>DAILY PRIZE</p>
              {/* <TooltipHover width={120} content="TODAY PRIZE">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>${data?.daily ? insertComma(Math.floor(data.daily)) : '0'}</p>
            </div>
          </li>
        </ul>
        <ul className="row-item">
          <li>
            <div className="title">
              <p>WEEKLY PRIZE</p>
              {/* <TooltipHover width={120} content="WEEKLY PRIZE">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>${data?.weekly ? insertComma(Math.floor(data.weekly)) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>MONTHLY PRIZE</p>
              {/* <TooltipHover width={120} content="MONTHLY PRIZE">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>${data?.monthly ? insertComma(Math.floor(data.monthly)) : '0'}</p>
            </div>
          </li>
          <li>
            <div className="title">
              <p>TOTAL PRIZE</p>
              {/* <TooltipHover width={120} content="TOTAL PRIZE">
                <div className="info-icon"></div>
              </TooltipHover> */}
            </div>
            <div className="inner">
              <p>${data?.total ? insertComma(Math.floor(data.total)) : '0'}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

const chainList = [
  {
    id: 0,
    title: "All"
  },
  {
    id: 137,
    title: "Polygon"
  },
  {
    id: 43114,
    title: "Avalanche"
  },
  {
    id: 8453,
    title: "Base"
  },
  {
    id: 204,
    title: "OpBNB",
  },
  {
    id: 42161,
    title: "Arbitrum",
  },
  {
    id: 37,
    title: "XPLA",
  },
]

export default function Resources() {
  const [chain, setChain] = useState(null);
  const chainSelectData = { select: chain, setSelect: setChain, selectList: chainList, id: "chain-select" }

  useEffect(() => {
    setChain(chainList[0])
  }, [])

  return (
    <div className="g-max-width section-layout resources">
      <div className="section-layout__header option-header">
        <p className="section-title resources">RESOURCES</p>
        {chain?.title ? <Select {...chainSelectData} /> : null}
      </div>
      <div className="resources-inner">
        <Information {...chainSelectData} />
        <WeeklyInformationMPT />
        <DailyInformationMPT />
        <div className="container-box solo">
          <DaliyTransactionsChart />
        </div>
        <div className="container-box solo">
          <DistributionVolumeChart />
        </div>
        <div className="container-box solo">
          <HistoricalActivityChart />
        </div>
      </div>
    </div>
  )
}