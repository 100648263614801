
import { LINK_ITEMS as LinkList } from 'constants/gnbList'
import FootLogo from 'assets/Logo/footer-logo.svg';

const social = [
  {
    url: 'https://twitter.com/miracleplaygg',
    icon: 'x',
  },
  {
    url: 'https://discord.com/invite/miracleplay',
    icon: 'discord',
  },
  {
    url: '',
    icon: 'youtube',
  },
  {
    url: 'https://t.me/miracleplay_eng',
    icon: 'telegram',
  },
  {
    url: 'https://medium.com/@miracleplay',
    icon: 'medium',
  },
  {
    url: 'https://zealy.io/c/miracleplay/questboard',
    icon: 'zealy',
  }
]

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <img className="logo" src={FootLogo} alt="footer logo img" />
        <ul className="social-wrapper">
          {social.map((item, index) => {
            return <li onClick={()=> window.open(item.url)} key={index} className={item.icon}></li>
          })}
        </ul>
        {/* <div className="link-wrapper">
          {Object.entries(LinkList).map(([key, value]) => {
            return <div className="link-group" key={key}>
              <p className="link-group__title">{key}</p>
              <ul className="link-group__item">
                {value.map((item, index) => {
                  return <li key={index}><p>{item.name}</p></li>
                })}
              </ul>
            </div>
          })}
        </div> */}
      </div>
    </footer>
  )
}

export default Footer;