import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setScreen } from 'store/modules/ui';
import { setChainExplorerUrls } from 'store/modules/chain';
import { Outlet } from 'react-router-dom';
import Header from './components/common/header/Header';
import Footer from './components/common/Footer';
import useWindowSizeCustom from "utils/useWindowSizeCustom";
import GlobalModal from 'components/modal/Global';
import { getChainExplorerUrlsAPI } from 'api/common'

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const screenSize = useWindowSizeCustom();
  const screen = useSelector(state => {
    return state?.ui?.screen;
  });
  const isMobile = screen.viewType.mobile;

  const getChainExplorerUrls = async () => {
    try {
      const res = await getChainExplorerUrlsAPI();
      dispatch(setChainExplorerUrls(res));
    } catch(e) {
      console.error("getChainExplorerUrls e:", e);
    }
  }
  useEffect(() => {
    getChainExplorerUrls()
  }, []);

  useEffect(() => {
    dispatch(setScreen(screenSize));
  }, [screenSize, dispatch]);

  return (
    <div className="wrapper" id="scrollbar">
      <Header />
      <main className={`container ${location.pathname !== '/' && isMobile ? 'double-head' : ''}`}>
        <Outlet />
      </main>
      <Footer />
      <GlobalModal />
    </div>
  );
}

export default Layout;