import { Button } from '@mui/base/Button';
import { CircularProgress } from '@mui/material';

export default function BaseButton(props) {
  const { label, onClick, size, isDisabled, isLoading } = props;
  return (
    <Button onClick={onClick} disabled={isDisabled || false} className={`base-btn ${size ? size : ''}`}>
      <p className={`${isLoading ? "hidden" : ""}`}>{label}</p>
      {isLoading && <div className="loading-wrap"><CircularProgress size={20} color="inherit" /></div>}
    </Button>
  )
}