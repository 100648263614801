
export const GNB_ITEMS = [
  {
    name: 'Play',
    pathName: '/plays',
  },
  {
    name: 'Transactions',
    pathName: '/txs',
  },
  {
    name: 'Tokens & NFTs',
    pathName: '/tokens-nfts',
  },
  {
    name: 'Resources',
    pathName: '/resources',
  }
]

export const LINK_ITEMS = {
  "ABOUT": [
    {
      name: "Blog",
      link: ""
    },
    {
      name: "Privacy Policy",
      link: ""
    },
    {
      name: "Careers",
      link: ""
    },
  ],
  "STAKING": [
    {
      name: "Validators",
      link: ""
    },
    {
      name: "Delegations",
      link: ""
    },
    {
      name: "Statistics",
      link: ""
    },
    {
      name: "FAQ",
      link: ""
    },
  ],
  "API": [
    {
      name: "Docs",
      link: ""
    },
  ],
  "SOCIAL": [
    {
      name: "Telegram",
      link: ""
    },
    {
      name: "Twitter",
      link: ""
    },
    {
      name: "Discord",
      link: ""
    },
  ],
  "CONTACTS": [
    {
      name: "Feedbacks",
      link: ""
    },
    {
      name: "Help",
      link: ""
    },
  ],
}