export const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000) + 'M';
  } else if (number >= 1000) {
    return (number / 1000) + 'K';
  } else {
    return number.toString();
  }
}

export const formatDate = (dateString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dateOnlyString = dateString.split('T')[0];
  const [year, month, day] = dateOnlyString.split('-');
  const monthName = months[parseInt(month, 10) - 1];
  return `${monthName} ${day}`;
}