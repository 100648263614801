import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { concatAddress } from 'utils/concat';
import { insertComma } from 'utils/insertComma';
import CopyItem from 'components/ui/CopyItem';
import Pagination from '@mui/material/Pagination';
import { getDateTimezoneEn, timeBefore } from 'utils/timeFormat';
import { getPlayListAPI } from 'api/pages'
import constants from 'constants';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import useChainInfo from 'hooks/useChainInfo';

const chain = constants.chain
const token = constants.token

const tableHead = [
  "chain",
  "game",
  "age",
  "hash",
  "from",
  // "fees",
]

export default function Plays() {
  const isMobile = useSelector(state => { return state?.ui?.screen.viewType.mobile; });
  const screenWidth = useSelector(state => { return state?.ui?.screen.size.width; });
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [count, setCount] = useState(null);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(null);
  const { setChainScanUrl } = useChainInfo();

  const getList = async (p) => {
    if (loading || !limit) return;
    setLoading(true);
    const pages = p ?? page;
    try {
      const res = await getPlayListAPI(pages, limit);
      // console.log("res: ", res)
      setList(res?.list);
      setTotal(res?.total)
      const count = Math.ceil(res?.total / limit);
      // console.log("count: ", count)
      setCount(count);
    } catch(e) {
      console.error("e: ", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getList();
  }, [page, limit])

  useEffect(() => {
    if (!screenWidth) return;
    setLimit(screenWidth <= 768 ? 5 : screenWidth > 768 ? 10 : null)
  }, [screenWidth]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="g-max-width section-layout latest-play">
      <div className="section-layout__header">
        <p className="section-title latest-play">LATEST PLAY</p>
        <p className="section-length">Total of <span>{total ? insertComma(total) : '0'}</span> plays</p>
      </div>
      <div className="section-layout__body">
        {/* {loading ? <div className="loading-wrapper"><CircularProgress size={60} color="inherit" /></div> : */}
        <div className="table-container latest-play-list">
          {isMobile ? <ul className="table-container__mobile-header">
            <li><p>chain</p></li>
            <li><p>game</p></li>
          </ul> : null}
          {isMobile ? <div className="m-table-inner">
            {loading ? <div className="m-table-inner__loading"><CircularProgress size={50} color="inherit" /></div> : list?.length ? list.map((body, index) => {
              const pathMove = () => {
                nav('/play/detail', { state: body });
              }
              return <div key={index} className="m-table-inner__item">
                <div className="item-head">
                  <div className="item-head__symbol">
                    {body?.ChainID ? <img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]}} symbol icon`} /> : null}
                    <p>{(body.ContractName && body.ContractType) ? body.ContractName.replace(`_${body.ContractType}`, '') : '-'}</p>
                  </div>
                </div>
                <ul className="item-list">
                  <li>
                    <p>HASH</p>
                    <span className="link" onClick={pathMove}>{body?.TxHash ? concatAddress(body.TxHash) : '-'}</span>
                  </li>
                  <li>
                    <p>FROM</p>
                    <span onClick={() => window.open(setChainScanUrl(body.ChainID, body.From, 'address'))} style={{cursor: 'pointer'}}>{body?.From ? concatAddress(body.From) : '-'}</span>
                  </li>
                </ul>
                <ul className="item-foot">
                  <li>
                    <p className="first">{body?.CreatedAt ? timeBefore(new Date(body.CreatedAt).getTime()) : '-'}</p>
                    <span>{body?.CreatedAt ? getDateTimezoneEn(new Date(body.CreatedAt).getTime()) : '-'}</span>
                  </li>
                  {/* <li>
                    <span className="first">FEE</span>
                    <div className="fee"><p>{body.fee}</p><span>{body.chain.token}</span></div>
                  </li> */}
                </ul>
              </div>
            }) : null}
          </div>
          : <table className="table-inner">
            <colgroup>
              {tableHead.map((head, index) => {
                return <col key={index} className={head} />;
              })}
            </colgroup>
            <thead className="table-inner__head">
              <tr>{tableHead.map((head, index) => {
                return <th key={index} className={head}><p>{head}</p></th>
              })}</tr>
            </thead>
            {loading ?
            <tbody className="table-inner__loading">
              <tr className="inner"><td className="inner__progress"><CircularProgress size={60} color="inherit" /></td><td></td></tr>
              {[...Array(9)].map((_, index) => (<tr key={index}><td></td><td></td><td></td><td></td><td></td></tr>))}
            </tbody> :
            <tbody className="table-inner__body">
              {list?.length ? list.map((body, index) => {
                return <tr key={index}>
                  <td className="symbol">{body.ChainID ?<img src={require(`assets/Symbol/${chain[body.ChainID]}.svg`)} alt={`${chain[body.ChainID]} symbol icon`} /> : null}</td>
                  <td className="str"><p>{(body.ContractName && body.ContractType) ? body.ContractName.replace(`_${body.ContractType}`, '') : '-'}</p></td>
                  <td className="column">
                    <div className="column__inner">
                      <p>{body?.CreatedAt ? timeBefore(new Date(body.CreatedAt).getTime()) : '-'}</p>
                      <span>{body?.CreatedAt ? getDateTimezoneEn(new Date(body.CreatedAt).getTime()) : '-'}</span>
                    </div>
                  </td>
                  <td className="concat w-90">{body?.TxHash ? <CopyItem data={body} path={`/play/detail`} value={body.TxHash} styled="start" /> : '-'}</td>
                  <td className="concat w-90">{body?.From ? <CopyItem open={setChainScanUrl(body.ChainID, body.From, 'address')} value={body.From} styled="start" /> : '-'}</td>
                  {/* <td className="two"><div className="two__inner"><p>{body.fee}</p><span>{body.chain.token}</span></div></td> */}
                </tr>
              }) : null}
            </tbody>}
          </table>}
        </div>
        <div className="pagenation-wrapper">
          <Pagination className="pagenation-custom" count={count ?? 1} page={page} onChange={handleChange} />
        </div>
      </div>
    </div>
  )
}
