import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './Layout';
import NotFound from 'pages/error/NotFound';
import Home from './pages/Home';
import Plays from './pages/play/Plays';
import PlayDetails from './pages/play/Details';
import Txs from './pages/Txs';
import TokensNfts from './pages/TokensNfts';
import Resources from './pages/Resources';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path='/plays' index element={<Plays />} />
        <Route path='/play/detail' index element={<PlayDetails />} />
        <Route path='/txs' index element={<Txs />} />
        <Route path='/tokens-nfts' index element={<TokensNfts />} />
        <Route path='/resources' index element={<Resources />} />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Route>
    </Routes>
  );
}

export default App;
