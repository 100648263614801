import { MenuItem, Select, styled as MUIStyled } from "@mui/material";
import SelectCheckImage from 'assets/Icon/select-check.svg';

const Menucomponent = MUIStyled(MenuItem)({
  backgroundColor: '#242431',
  boxShadow: 'none',
  fontSize: '12px',
  lineHeight: '20px',
  padding: '0 16px 0 16px !important',
  height: '35px',
  minHeight: '25px',
  '.title': {
    color: '#7D7C97',
  },
  '&:hover': {
    'backgroundColor': 'transparent',
  },
  '&.Mui-selected': {
    // justifyContent: 'space-between',
    posisiton: 'relative',
    background: '#242431',
    '.title': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&::after': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
      right: '16px',
      width: '16px',
      height: '16px',
      content: '""',
      display: 'block',
      background: `url(${SelectCheckImage}) no-repeat center/contain`,
    }
  }
});


export default function SelectComponent(props) { // balance & symbol name select
  const { select, setSelect, selectList, id, objData } = props;
  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  return (
    <Select
      id={id}
      className="base-select default-select"
      value={select}
      onChange={handleChange}
      >
      {selectList?.map((item, index) => {
      return <Menucomponent key={index} value={item}>
        <p className="title">{objData ? Object.keys(item) : item?.title ? item.title : item}</p>
      </Menucomponent>
      })}
    </Select>
  );
}